import React, { useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import ProductDetailContent from './ProductDetailContent'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../apiGeneralFunction';
import ShoppingCartpage from '../ShoppingCart/ShoppingCartpage';
import MyAddressTabContent from '../UserProfile/UserProfileContent/MyAddressTabContent';

const ProductDetail = () => {

  // const location = useLocation();
  // const { id } = location?.state;

  // console.log("ProductDetail id", id);
  // localStorage.setItem("ProductDetail id",id)
  // const navigate = useNavigate();

  // const [data, setData] = useState(null);
  // const [feature, setFeature] = useState(null);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [])

  // const fetchData = async () => {

  //   const tokenValue = localStorage?.getItem('login api response token');
  //   const storedToken = JSON?.parse(tokenValue);
  //   // if (!storedToken) {
  //   //   navigate('/');
  //   //   // return;
  //   // }

  //   const user_id = localStorage?.getItem('user_data');
  //   const userId = JSON.parse(user_id);

  //   const ProductId = localStorage.getItem("ProductDetail id") || ''

  //   const abortController = new AbortController();

  //   try {

  //     const formdata = new FormData();
  //     formdata.append("device_type", "web");
  //     formdata.append("user_id", userId?.user_id);
  //     // formdata.append("user_id", "17");
  //     formdata.append("product_id", ProductId);

  //     const result = await fetchWithFormDataToken(`api/v1/product_details`, formdata, abortController, null);
  //     if (result?.status === true) {
  //       setData(result?.data)
  //     }
  //     else {

  //     }
  //   } catch (error) {

  //   }
  //   finally {

  //     abortController.abort();
  //   }
  // };

  // console.log("ProductDetail", data);

  return (
    <Layout>
      {/* <ProductDetailContent data={data}/> */}

      <Routes>
        <Route path="/" element={<ProductDetailContent
        // data={data} 
         />} />
        {/* <Route path="/shoppingcart" element={<ShoppingCartpage />} /> */}
        {/* <Route path="/myaddress" element={<MyAddressTabContent />} /> */}
      </Routes>
    </Layout>
  )
}

export default ProductDetail