import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { postFormData } from "../../../apiGeneralFunction";
import ImgComp from "../../ReusableComp/ImgComp";
import * as Images from '../../../assets';
import Layout from "../../ReusableComp/Layout";


const Verification = ({ setShowLocation, setShowVerify }) => {

  const numberOfDigits = 4;
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);
  const [timer, setTimer] = useState(60); // 1 minute = 60 seconds
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const register_number = localStorage?.getItem("register api response");
  const user_phone_no = JSON?.parse(register_number);


  useEffect(() => {
    if (isTimerRunning) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
        if (timer === 1) {
          setIsTimerRunning(false); // Stop the timer when it reaches 0
        }
      }, 1000); // 1000ms = 1 second

      return () => clearInterval(intervalId);
    }
  }, [isTimerRunning, timer]);

  const handleSubmit = async () => {

    if (otp?.join("") === '') {
      toast.error('Please enter the  OTP', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }


    const register_number = localStorage?.getItem("register api response");
    const user_phone_no = JSON?.parse(register_number);


    const formdata = new FormData();
    formdata.append("country_code", "+91");
    formdata.append("phone", user_phone_no);
    formdata.append("verification_code", otp.join(""));
    formdata.append("device_token", "");
    formdata.append("device_type", "Web");
    formdata.append("api_version", "");
    formdata.append("app_version", "");
    formdata.append("os_version", "");
    formdata.append("device_model_name", "");
    formdata.append("app_language", "");


    try {
      const result = await postFormData('api/v1/verifyotp', formdata);

      if (result?.status === true) {
        localStorage.setItem("login api response token", JSON.stringify(result?.data?.token))
        localStorage.setItem("user_data", JSON.stringify(result?.data))
        navigate('/location')
        setShowVerify(false)
        setShowLocation(true)
        toast.success(result?.message, {
          position: 'top-center',
          autoClose: 3000,
        });
      }
      else {

        toast.error(result?.message, {
          position: 'top-center',
          autoClose: 3000,
        });
        localStorage.clear()
        //  navigate('/register_with_email');
        setShowLocation(false)
      }
    } catch (error) {

    }


  };

  function handleChange(value, index) {

    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }

  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter') {
      handleSubmit();
    }
  };

  const OTPVerificationImage = () => (
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/33182bb4a34d51254c94257e2b3e52809ccb4863687eb854d868e5b9fc8a3488?apiKey=8e5983036f284a13866aae97819cdb73&"
      alt="OTP Verification"
      className="max-w-full aspect-[0.99] w-[114px]"
    />
  );

  const OTPInputField = () => (
    <div className="shrink-0 w-16 h-16 border border-gray-200 border-solid stroke-[1px] stroke-gray-200" />
  );

  const OTPInputFields = () => (


    <div className="flex gap-5 self-stretch px-20 mt-5 max-lg:flex-wrap max-lg:px-5 items-center justify-center">
      <div className='flex items-center gap-4'>
        {otp.map((digit, index) => (
          <input
            type="text" pattern="^[0-9]"
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}

            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                e.preventDefault();
              }
              handleKeyPress(e);
            }}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            className={`border w-16 h-16 max-sm:w-12 max-sm:h-12 text-center text-black p-3 rounded-md block bg-white focus:border-2 focus:outline-none appearance-none border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:border-gray-200 focus:ring-0`}


          />
        ))}
      </div>
    </div>
  );

  const VerifyButton = () => (
    <button
      onClick={() => handleSubmit()}
      className="justify-center items-center px-16 py-4 mt-5 max-w-full text-lg font-medium text-center text-white whitespace-nowrap bg-red-700 rounded max-lg:px-5">
      Verify
    </button>
  );

  const PolicyLinks = () => (
    <>
      <div className="flex gap-5 justify-between self-stretch mt-9 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full">
        <a href="#">Privacy Policy</a>
        <a href="#">Terms &amp; Conditions</a>
      </div>
      <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
    </>
  );

  const OTPVerificationContent = () => (
    <div className="flex flex-col grow justify-between items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full">
      <OTPVerificationImage />
      <h1 className="mt-9 text-3xl leading-10 text-center text-red-700">
        OTP <span className="font-medium text-red-700">Verification</span>
      </h1>
      <p className="mt-3 text-base leading-6 text-center text-neutral-400 max-lg:max-w-full">
        The confirmation code was sent on {user_phone_no}
      </p>
      <OTPInputFields />
      <p className="mt-5 text-base leading-6 text-neutral-900">
        <span className="font-medium text-neutral-400">Code expires in: </span>{" "}
        <span className="font-medium text-neutral-900">01:00</span>
      </p>
      <VerifyButton />
      <p className="mt-5 text-base leading-6 text-red-700">
        <span className="font-medium text-neutral-400">Dont get the code? </span>{" "}
        <a href="#" className="font-medium text-red-700">
          Resend
        </a>
      </p>
      <PolicyLinks />
    </div>
  );

  const ImageWithMarker = ({ src, alt, markerSrc, markerAlt }) => (
    <div className="flex flex-col grow shrink-0 items-start basis-0 w-fit max-lg:max-w-full">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="self-stretch w-full aspect-[1.56] max-lg:max-w-full"
      />
      <img
        loading="lazy"
        src={markerSrc}
        alt={markerAlt}
        className="ml-8 aspect-square fill-white w-[9px] max-lg:ml-2.5"
      />
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fa939dc9389950b047a9798d5698e733c143c6ed9cdba4e773904c72d6c72e6?apiKey=8e5983036f284a13866aae97819cdb73&"
        alt=""
        className="mt-5 aspect-square fill-red-200 w-[30px]"
      />
    </div>
  );

  const ImageSection = () => (
    <>
      {/* <div
      // className="flex flex-col grow pr-20 pb-20 pl-9 w-full  max-lg:px-5 max-lg:max-w-full rounded-r-2xl"
      className="flex flex-col grow  pl-9 w-full  max-lg:px-0 max-lg:max-w-full  rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none"
    > */}
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0bf251b6dfb736d69c01d9ed45b753009a5e944787d10e52b4a710b7b9c19322?apiKey=8e5983036f284a13866aae97819cdb73&"
        alt="Illustration"
        className="self-center w-80 max-w-full aspect-[2.13]"
      />
      <div className="flex gap-5 self-start mt-2 max-lg:flex-wrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e36f7d2d697d09c44b8e3a49930ccc43b340cb06bd72d24a51cc1f1b72eb977e?apiKey=8e5983036f284a13866aae97819cdb73&"
          alt=""
          className="shrink-0 self-start mt-24 aspect-square fill-red-200 w-[18px] max-lg:mt-10"
        />
        <ImageWithMarker
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2a0bc6e8885531479721fbc94918cebaae6f5897726bc7b4d97a700a874efdc?apiKey=8e5983036f284a13866aae97819cdb73&"
          alt="Map"
          markerSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/08d049856bab98afe04014d55fa37dddc91e3f5f52026cb4635a093fe602467e?apiKey=8e5983036f284a13866aae97819cdb73&"
          markerAlt="Marker"
        />
      </div> */}
      <ImgComp
        src={Images.verification_otp}
        srcSet={Images.verification_otp}
        alt={'verification otp'}
        className={'h-full w-full rounded-r-2xl max-lg:rounded-t-none max-lg:rounded-b-2xl'}
      />
      {/* </div> */}
    </>
  );

  return (
    <Layout>
      <div className="flex justify-center items-center px-16 py-20 bg-white max-lg:px-5">
        <div className="max-w-full rounded-2xl shadow-2xl w-[1296px]">
          <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
            <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full  rounded-2xl">
              {/* <OTPVerificationContent /> */}
              <div className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full">
                <OTPVerificationImage />
                <h1 className="mt-9 text-3xl leading-10 text-center ">
                  OTP <span className="text-red-700 font-bold">Verification</span>
                </h1>
                <p className="mt-3 text-base leading-6 text-center text-neutral-400 max-lg:max-w-full">
                  The confirmation code was sent on {user_phone_no}
                </p>
                {/* <OTPInputFields /> */}
                <div className="flex gap-5 self-stretch px-20 mt-5 max-lg:flex-wrap max-lg:px-5 items-center justify-center">
                  <div className='flex items-center gap-4'>
                    {otp.map((digit, index) => (
                      <input
                        type="text" pattern="^[0-9]"
                        key={index}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        inputMode="numeric"
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                            e.preventDefault();
                          }
                          handleKeyPress(e);
                        }}
                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                        className={`border w-16 h-16 max-sm:w-12 max-sm:h-12 text-center text-black p-3 rounded-md block bg-white focus:border-2 focus:outline-none appearance-none border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] focus:border-gray-200 focus:ring-0`}

                      />
                    ))}
                  </div>
                </div>
                <p className="mt-5 text-base leading-6 text-neutral-900">
                  <span className="font-medium text-neutral-400">Code expires in: </span>{" "}
                  <span className="font-medium text-neutral-900">{timer} seconds</span>
                </p>
                {/* <VerifyButton /> */}
                <button
                  // onClick={() => handleSubmit()}
                  onClick={handleSubmit}
                  className=" justify-center items-center px-16 py-4 mt-5 max-w-full text-lg font-medium text-center text-white whitespace-nowrap bg-red-700 rounded max-lg:px-5 max-lg:py-2">
                  Verify
                </button>
                <p className="mt-5 text-base leading-6 text-red-700">
                  <span className="font-medium text-neutral-400">Dont get the code? </span>{" "}
                  <button
                    // onClick={() => handleSubmit()}
                    onClick={handleSubmit}
                    className="font-medium text-red-700 ">
                    Resend
                  </button>
                </p>
                <PolicyLinks />
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full  hidden sm:block">
              <ImageSection />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </Layout>
  );
}

export default Verification;