// SearchContext.js
import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();
export const useSearch = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
    // You can also fetch and set search results here
  };

  return (
    <SearchContext.Provider
      // value={{ searchQuery, searchResults, setSearchResults, handleSearch }}
      value={{ query, setQuery }}
    >
      {children}
    </SearchContext.Provider>
  );
};
