import React from 'react'
import Layout from '../../ReusableComp/Layout'
import EmptyCartContent from './EmptyCartContent'


const AddToCart = () => {
    return (
        <Layout>
            <EmptyCartContent/>
        </Layout>
    )
}

export default AddToCart