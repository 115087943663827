import React, { useEffect } from "react";

import UserProfileContent from "./UserProfileContent";
import Layout from "../../ReusableComp/Layout";


const UserProfile = () => {

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);

    return (
        <>
        <Layout>
            <UserProfileContent/>
        </Layout>
        </>
    );
}

export default UserProfile