import React, { forwardRef, useEffect, useRef, useState, memo } from 'react'
import Layout from '../../../../ReusableComp/Layout';
import { width } from '../../../../ReusableComp/Buttons';
import { fetchData, fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { AddressModalBox } from '../../../../ReusableComp/PaymentResponseModalBox';
import { CustomDropdown, FormInput } from '../../../../ReusableComp/InputType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const MyAddressTabContent = ({ }) => {

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [data, setData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [updateId, setUpdateId] = useState('');

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [addAddressAllData, setAddAddressAllData] = useState(null);


  const [formData, setFormData] = useState({
    flat: '',
    street: '',
    country: '',
    countryLabel: '',
    city: '',
    pincode: '',
    state: '',
    stateLabel: '',
    landmark: '',
    addressType: "Home",
    isDefault: false,
  });


  useEffect(() => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitudeCords = position.coords.latitude;
          setLatitude(latitudeCords)
          const longitudeCords = position.coords.longitude;
          setLongitude(longitudeCords)
        })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }

  }, [])

  const countryName = countryList?.find((item) => item?.value === Number(data?.country))?.label || '';
  const stateName = stateList?.find((item) => item?.value === Number(data?.state))?.label || '';
  const cityName = cityList?.find((item) => item?.value === Number(data?.state))?.label || '';
  console.log("countryName", cityList, cityName);


  // useEffect(() => {
  //   if (initialData) {
  //     setFormData(initialData);
  //   }
  // }, [initialData]);

  const handleChange = (e) => {

    const { name, id, flat, value, area, city, state, country, pincode, type, checked, } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      [flat]: value,
      [area]: value,
      [city]: value,
      [state]: value,
      [country]: value,
      [pincode]: value,
      [id]: type === "checkbox" ? checked : value,
      addressType: value,
    }));

    // if (id === 'isEditDefault') {
    //   setFormEditData((prevData) => ({ ...prevData, isDefault: checked }));
    //   console.log('Updated formEditData:', formEditData); // Add this line
    // }

  };

  const [formEditData, setFormEditData] = useState({
    flat: '',
    street: '',
    country: '',
    countryLabel: '',
    city: '',
    pincode: '',
    state: '',
    stateLabel: '',
    landmark: '',
    addressType: '',
    isDefault: false,
  });




  console.log("country id and name", formEditData);

  const handleEditChange = (e) => {

    const { name, id, flat, value, area, city, state, country, pincode, type, checked, } = e.target;
    setFormEditData((prevData) => ({
      ...prevData,
      [name]: value,
      [flat]: value,
      [area]: value,
      [city]: value,
      [state]: value,
      [country]: value,
      [pincode]: value,
      [id]: type === "checkbox" ? checked : value,
      addressType: value,
    }));

  };

  console.log("formEditData", formEditData);



  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  console.log("formData.isDefault", formData);



  const handleSubmit = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    const renderContent = (title) => {
      switch (title) {
        case 'Home':
          return "1";
        case 'Office':
          return "2";
        case 'Other':
          return "3";
        default:
          return null;
      }
    };

    console.log("address_type", renderContent(formData?.addressType));
    console.log("formData?.isDefault === true", formData?.isDefault);

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId?.user_id);
      formdata.append("address_type", renderContent(formData?.addressType));
      formdata.append("default_address", formData?.isDefault === true ? "1" : "0");
      formdata.append("landmark", formData?.landmark);
      formdata.append("city_id", formData?.city);
      formdata.append("state_id", formData?.state);
      formdata.append("country_id", formData?.country);
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);
      formdata.append("flatno_building", formData?.flat);
      formdata.append("street_area", formData?.street);
      formdata.append("city", formData?.cityLabel);
      formdata.append("state", formData?.stateLabel);
      formdata.append("country", formData?.countryLabel);
      formdata.append("pincode", formData?.pincode);

      const result = await fetchWithFormDataToken(`api/v1/add_user_address`, formdata, storedToken, abortController);

      console.log("add user address response", result);
      if (result?.status === true) {
        setTimeout(() => {
          toast.success(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
        setIsModalOpen(false)
        addressData()
        setFormData({})

      }
      else {
        setTimeout(() => {
          toast.error(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  const handleUpdateSubmit = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    const renderContent = (title) => {
      switch (title) {
        case 'Home':
          return "1";
        case 'Office':
          return "2";
        case 'Other':
          return "3";
        default:
          return null;
      }
    };

    const matchingCountry = countryList.find((country) => country?.value === Number(formEditData?.countryLabel));
    console.log("update api matchingCountry id", matchingCountry?.value);

    const matchingState = stateList.find((state) => state?.value === Number(formEditData?.stateLabel));
    console.log("matchingState id", matchingState?.value);

    const matchingCity = cityList.find((city) => city?.value === Number(formEditData?.cityLabel));
    console.log("matchingCountry id", matchingCity?.value);

    try {

      const formdata = new FormData();

      formdata.append("id", updateId);
      formdata.append("user_id", userId?.user_id);
      formdata.append("address_type", renderContent(formEditData?.addressType) || formEditData?.addressType);
      formdata.append("default_address", formEditData?.isDefault === true ? "1" : "0");
      formdata.append("landmark", formEditData?.landmark);
      formdata.append("city_id", matchingCity?.value || formEditData?.cityLabel);
      formdata.append("state_id", matchingState?.value || formEditData?.stateLabel);
      formdata.append("country_id", matchingCountry?.value || formEditData?.countryLabel);
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);
      formdata.append("flatno_building", formEditData?.flat);
      formdata.append("street_area", formEditData?.street);
      formdata.append("city", formEditData?.city);
      formdata.append("state", formEditData?.state);
      formdata.append("country", formEditData?.country);
      formdata.append("pincode", formEditData?.pincode);

      const result = await fetchWithFormDataToken(`api/v1/update_user_address`, formdata, storedToken, abortController);

      console.log("add user address response", result);
      if (result?.status === true) {
        setTimeout(() => {
          toast.success(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
        setIsModalEditOpen(false)
        addressData()
        setFormEditData({})
        setShow(false)
      }
      else {
        setTimeout(() => {
          toast.error(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }


  const handleDelete = async (Id) => {

    console.log("handleDelete Id", Id);


    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      formdata.append("id", Id);

      const result = await fetchWithFormDataToken(`api/v1/delete_user_address`, formdata, storedToken, abortController);

      console.log("add user address response", result);
      if (result?.status === true) {
        setTimeout(() => {
          toast.success(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
        addressData()

      }
      else {
        setTimeout(() => {
          toast.error(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }


  const handleSetAsDefault = async (Id) => {

    console.log("handleDelete Id", Id);


    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId.user_id);
      formdata.append("address_id", Id);

      const result = await fetchWithFormDataToken(`api/v1/set_user_address_default`, formdata, storedToken, abortController);

      console.log("add user address response", result);
      if (result?.status === true) {
        setTimeout(() => {
          toast.success(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
        addressData()

      }
      else {
        setTimeout(() => {
          toast.error(result?.message || '', {
            position: 'top-center',
            autoClose: 3000,
          });
        }, 1000);
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const controller = new AbortController();

  useEffect(() => {

    addressData();
    fetchCountry();

    return () => {

      controller.abort();
    };
  }, []);



  const fetchCountry = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
      // return;
    }

    const abortController = new AbortController();

    try {

      const result = await fetchData(`api/v1/list_country`, storedToken, abortController);

      console.log("delete category response", result);
      if (result?.status === true) {

        const formattedOptions = result?.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setCountryList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  const addressData = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);

      const result = await fetchWithFormDataToken(`api/v1/list_user_address`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data)
      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  console.log("address data lisdt", data);

  const editButton = (addressAddData) => {
    console.log("editButton ", addressAddData);
    setIsModalEditOpen(true)
    setAddAddressAllData(addressAddData)

  }

  const matchingCountry = countryList.find((country) => country?.value === Number(addAddressAllData?.country));
  console.log("matchingCountry id", matchingCountry, countryList, formEditData);

  const matchingState = stateList.find((state) => state?.value === Number(addAddressAllData?.state));
  console.log("matchingState id", stateList, formEditData, addAddressAllData);

  const matchingCity = cityList.find((city) => city?.value === Number(addAddressAllData?.city));
  console.log("matchingCountry id", addAddressAllData);

  useEffect(() => {


    if (addAddressAllData) {
      setFormEditData({
        flat: addAddressAllData.flatno_building,
        street: addAddressAllData.street_area,
        country: matchingCountry?.label,
        countryLabel: addAddressAllData.country,
        cityLabel: addAddressAllData.city,
        city: matchingCity?.label,
        pincode: addAddressAllData.pincode,
        state: matchingState?.label,
        stateLabel: addAddressAllData.state,
        landmark: addAddressAllData.landmark,
        addressType: renderEditContent(addAddressAllData.address_type),
        isDefault: addAddressAllData.isDefault,
      });
    }
  }, [addAddressAllData]);


  const AddressCard = ({ addressAddData, default_address, flatno_building, address_type, onEdit, onDelete, onSetDefault, street_area, landmark, city, state, country, pincode }) => {
    // const address = flatno_building+ "," + street_area + "," + landmark + "," + city + "," + state + "," + country + "," + pincode
    console.log("AddressCardaddressData", addressAddData?.default_address, default_address);
    setUpdateId(addressAddData?.id)

    const address = [
      flatno_building || '',
      street_area || '',
      landmark || '',
      city || '',
      state || '',
      country || '',
      pincode || ''
    ].filter(part => part.trim() !== '').join(', ');

    const renderContent = (title) => {
      switch (title) {
        case '1':
          return "Home";
        case '2':
          return "Office";
        case '3':
          return "Other";
        default:
          return null;
      }
    };

    return (
      <section className="flex flex-col p-4 mt-4 max-w-full text-xs leading-5 bg-white rounded-lg border border-gray-200 border-solid w-fit">
        <div className="flex gap-2 self-start text-base font-medium whitespace-nowrap text-neutral-900">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/81c7b68c51a109092978692bdc2e168b897d79acfb3df270974840bc788ab4fd?apiKey=8e5983036f284a13866aae97819cdb73&" alt={` icon`} className="shrink-0 w-6 aspect-square" />
          <div>{renderContent(address_type)}</div>
        </div>
        <div className="mt-2 text-neutral-400">{address}</div>
        <hr className='my-3' />
        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4dd74eeba88b13bf74115473b0bfedc8f1826b60f5ea7c751d7d8fe9bde4281?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="mt-3 w-full border border-gray-200 border-solid stroke-[1px] stroke-gray-200" /> */}
        <div className="flex  justify-between  grid grid-cols-3  max-md:pr-5">
          <div className="flex gap-1 text-neutral-400 cursor-pointer" onClick={() => editButton(addressAddData)}>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b41e5494006f14e4f46cdb167ece5312c535a92eeb5fb77f4d38ac1aa05f41e?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Edit icon" className="shrink-0 self-start w-4 aspect-square" />
            <div>Edit</div>
          </div>
          <div className="flex gap-1 text-red-700 cursor-pointer" onClick={onDelete}>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d990d141576b72ae25a0527864566633b5696f0be489d16251c6250dcc3331ad?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Delete icon" className="shrink-0 self-start w-4 aspect-square" />
            <div onClick={() => handleDelete(addressAddData?.id)}>Delete</div>
          </div>
          {addressAddData?.default_address === "1" &&
            <div className="flex gap-1 text-amber-500 cursor-pointer items-center"
              onClick={() => handleSetAsDefault(addressAddData?.id)}
            >
              <i className='fa fa-check text-amber-500 text-[10px] border border-amber-500 px-0.5 py-0.5 rounded-full'></i>
              {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ff0eaee16741e8880b1cafca05a61ac0c35cb0d393c1573f5be7b5b1810ab20?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Set as Default icon" className="shrink-0 self-start w-4 aspect-square" /> */}
              <div>Set as Default</div>
            </div>
          }
        </div>
      </section>
    );
  }
  const officeAddresses = [
    { title: "Office", address: "848 Freed Drive, Stockton, California, 95202", onEdit: () => { }, onDelete: () => { } },
  ];

  const addresses = [
    { title: "Home", address: "848 Freed Drive, Stockton, California, 95202", onEdit: () => { }, onDelete: () => { }, onSetDefault: () => { } },
    { title: "Others", address: "848 Freed Drive, Stockton, California, 95202", onEdit: () => { }, onDelete: () => { }, onSetDefault: () => { } },
  ];

  useEffect(() => {
    if (formData?.country) {
      fetchState(formData?.country);
    }
  }, [formData?.country]);

  useEffect(() => {
    if (addAddressAllData?.country) {
      fetchState(addAddressAllData?.country);
    }
  }, [addAddressAllData?.country]);

  useEffect(() => {
    if (formData?.country && formData?.state) {
      fetchCity(formData?.country, formData?.state,);
    }
  }, [formData?.country, formData?.state]);

  useEffect(() => {
    if (addAddressAllData?.country && addAddressAllData?.state) {
      fetchCity(addAddressAllData?.country, addAddressAllData?.state,);
    }
  }, [addAddressAllData?.country, addAddressAllData?.state]);


  useEffect(() => {
    if (formEditData?.country) {
      fetchState(formEditData?.country);
    }
  }, [formEditData?.country]);

  useEffect(() => {
    if (formEditData?.country && formEditData?.state) {
      fetchCity(formEditData?.country, formEditData?.state);
    }
  }, [formEditData?.country, formEditData?.state]);

  useEffect(() => {
    if (addAddressAllData?.country && addAddressAllData?.state) {
      fetchCity(addAddressAllData?.country, addAddressAllData?.state);
    }
  }, [addAddressAllData?.country, addAddressAllData?.state]);

  const fetchState = async (countryId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("country_id", countryId);

      const result = await fetchWithFormDataToken(`api/v1/list_state`, formdata, storedToken, abortController);

      console.log("delete category response", result);
      if (result?.status === true) {

        const formattedOptions = result.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setStateList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }

  const fetchCity = async (countryId, stateId) => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("country_id", countryId);
      formdata.append("state_id", stateId);

      const result = await fetchWithFormDataToken(`api/v1/list_city`, formdata, storedToken, abortController);

      console.log("delete category response", result);
      if (result?.status === true) {

        const formattedOptions = result.data.map(item => ({

          value: item?.id,
          label: item?.title
        }));
        setCityList(formattedOptions);
      }
      else {
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  }


  const AddressOptions = ({ selectedOption, onChange }) => {
    const [focusedOption, setFocusedOption] = useState(null);

  const handleKeyDown = (event, option) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange({ target: { value: option } });
    }
  };

  const handleFocus = (option) => {
    setFocusedOption(option);
  };

  const handleBlur = () => {
    setFocusedOption(null);
  };
  
    return(
    <div className="flex gap-4 self-start mt-4">
      {["Home", "Office", "Other"].map((option, index) => (
        <div key={option} 
         className="flex gap-2 text-sm leading-6 text-black whitespace-nowrap"
        // className={`flex gap-2 text-sm leading-6 text-black whitespace-nowrap ${
        //   focusedOption === option ? 'border border-blue-500 rounded' : ''
        // }`}
        >
          <input
            type="radio"
            id={option}
            name="addressType"
            value={option}
            // value={index + 1}
            checked={selectedOption === option}
            onChange={onChange}
            className="my-auto"
            // onKeyDown={(e) => handleKeyDown(e, option)}
            // onFocus={() => handleFocus(option)}
            // onBlur={handleBlur}
          />
          <label htmlFor={option} className="my-auto">{option}</label>
        </div>
      ))}
    </div>
  )};

  const renderEditContent = (title) => {

    console.log("title", title);
    switch (title) {
      case '1':
        return "Home";
      case '2':
        return "Office";
      case '3':
        return "Other";
      default:
        return null;
    }
  };


  const AddressEditOptions = ({ selectedOption, onChange }) => {
    return (
      <div className="flex gap-4 self-start mt-4">
        {["Home", "Office", "Other"].map((option) => (
          <div key={option} className="flex gap-2 text-sm leading-6 text-black whitespace-nowrap" >
            <input
              type="radio"
              id={option}
              name="addressEditType"
              value={option}
              checked={selectedOption === option}
              onChange={onChange}
              className="my-auto"
            />
            <label htmlFor="addressEditType" className="my-auto">{option}</label>
          </div>
        ))}
      </div>
    )

  };

  const onCancelBtn = () => {
    setIsModalOpen(false)
    setFormData({})

  }


  const modalContent = (
    <>
      <div className="flex flex-col rounded-2xl ">


        {/* <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4 text-sm max-md:flex-wrap "> */}
        <div className="flex gap-4 grid xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 max-sm:grid-cols-1">
          <FormInput
            label="Flat No./Building"

            name="flat"
            //placeholder="Enter flat no. or building"
            value={formData.flat}
            // onChange={(value) => {
            //   console.log("country hjson", value.id, value.label);
            //   setFormData(prev => ({ ...prev, flat: value }));
            // }}
            onChange={handleChange}
          // onChange={(e) => handleChange(e)}
          />



          <FormInput
            label="Street/Area"
            name="street"
            // placeholder="Enter street or area"
            value={formData.street}
            onChange={handleChange}
          />
          <FormInput
            label="Pincode"
            name="pincode"
            //placeholder="Enter pincode"
            value={formData.pincode}
            onChange={handleChange}
          />
          <CustomDropdown
            value={formData?.country}
            // onChange={(value) => setFormData(prev => ({ ...prev, country: value }))}
            onChange={(value) => {
              console.log("country hjson", value.id, value.label);
              setFormData(prev => ({ ...prev, country: value.id, countryLabel: value.label }));
            }}
            options={countryList}
            placeholder="Select Country"
            labelName={`Country`}
          />
          <CustomDropdown
            value={formData?.state}
            onChange={(value) => {
              setFormData(prev => ({ ...prev, state: value.id, stateLabel: value.label }));
            }}
            options={stateList}
            placeholder="Select State"
            labelName={`State`}
          />

          <CustomDropdown
            value={formData?.city}
            // onChange={(value) => setFormData(prev => ({ ...prev, city: value }))}
            onChange={(value) => {
              setFormData(prev => ({ ...prev, city: value.id, cityLabel: value.label }));
            }}
            options={cityList}
            placeholder="Select City"
            labelName={`City`}
          />

        </div>
        <div className="text-sm text-neutral-400 max-md:max-w-full mt-4 text-start">
          <label htmlFor="landmark" className="sr-only">
            Landmark (Optional)
          </label>
          Landmark (Optional)
        </div>
        <textarea
          name="landmark"
          className="max-sm:h-28 h-28 justify-center items-start px-3 py-2 mt-2 text-sm leading-5 bg-white rounded-md border border-gray-200 border-solid text-black max-md:pr-5 max-md:max-w-full"
          placeholder="Type message here..."
          value={formData.landmark}
          onChange={(e) => handleChange(e)}
          aria-label="Landmark (Optional)"
        ></textarea>
        <section className="self-start mt-4 text-base font-medium leading-7 text-black">
          Type of Address
        </section>
        <AddressOptions selectedOption={formData.addressType} onChange={handleChange} />
        <section className="flex gap-2 self-start mt-4 text-base leading-6 text-black">
          <input
            type="checkbox"
            id="isDefault"
            checked={formData.isDefault}
            onChange={(e) => handleChange(e)}
            className="shrink-0 my-auto w-4 aspect-square"
          />
          <label htmlFor="isDefault" className="my-auto">Use as default address</label>
        </section>
        <footer className="flex flex-col justify-center items-end px-16 mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full">
          <div className="flex gap-4">
            <button
              type="button"
              className="justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
              onClick={() => onCancelBtn()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              onClick={() => handleSubmit()}
            >
              Save Address
            </button>
          </div>
        </footer>
        {/* </form> */}
      </div>
    </>
  )

  console.log("formEditData", formEditData);

  const modalEditContent = (
    <>
      <div className="flex flex-col rounded-2xl">
        <div className="flex gap-4 grid grid-cols-3">
          <FormInput
            label="Flat No./Building"
            name="flat"
            //placeholder="Enter flat no. or building"
            value={formEditData.flat}
            onChange={handleEditChange}
          />

          <FormInput
            label="Street/Area"
            name="street"
            placeholder="Enter street or area"
            value={formEditData.street}
            onChange={handleChange}
          />
          <FormInput
            label="Pincode"
            name="pincode"
            placeholder="Enter pincode"
            value={formEditData.pincode}
            onChange={handleChange}
          />

        </div>
        {!show ?
          <>
            <div className="flex gap-4 grid grid-cols-3 w-full mt-5" onClick={() => setShow(true)}>
              <FormInput
                label="Country"
                name="country"
                value={formEditData.country}

              />
              <FormInput
                label="State"
                name="state"
                value={formEditData.state}
              />
              <FormInput
                label="City"
                name="city"
                value={formEditData.city}
              />
            </div>
          </>
          :
          <>
            <div className="flex gap-4 grid grid-cols-3 w-full mt-5">
              <CustomDropdown
                value={formEditData?.country}
                // onChange={(value) => setFormData(prev => ({ ...prev, country: value }))}
                onChange={(value) => {
                  console.log("country hjson", value.id, value.label);
                  setFormEditData(prev => ({ ...prev, country: value.id, countryLabel: value.label }));
                }}
                options={countryList}
                placeholder="Select Country"
                labelName={`Country`}
              />
              <CustomDropdown
                value={formEditData?.state}
                onChange={(value) => {
                  setFormEditData(prev => ({ ...prev, state: value.id, stateLabel: value.label }));
                }}
                options={stateList}
                placeholder="Select State"
                labelName={`State`}
              />

              <CustomDropdown
                value={formEditData?.city}
                onChange={(value) => {
                  setFormEditData(prev => ({ ...prev, city: value.id, cityLabel: value.label }));
                }}
                options={cityList}
                placeholder="Select City"
                labelName={`City`}
              />
            </div>
          </>
        }
        <div className="text-sm text-neutral-400 max-md:max-w-full mt-4 text-start">
          <label htmlFor="landmark" className="sr-only">
            Landmark (Optional)
          </label>
          Landmark (Optional)
        </div>
        <textarea
          name="landmark"
          className="justify-center h-20 items-start px-3 py-2 mt-2 text-sm leading-5 bg-white rounded-md border border-gray-200 border-solid text-neutral-400 max-md:pr-5 max-md:max-w-full"
          placeholder="Type message here..."
          value={formEditData.landmark}
          onChange={(e) => handleEditChange(e)}
          aria-label="Landmark (Optional)"
        ></textarea>
        <section className="self-start mt-4 text-base font-medium leading-7 text-black">
          Type of Address
        </section>
        <AddressEditOptions selectedOption={formEditData.addressType} onChange={handleEditChange} />
        <section className="flex gap-2 self-start mt-4 text-base leading-6 text-black">
          <input
            type="checkbox"
            id="isEditDefault"
            checked={formEditData.isDefault}
            onChange={(e) => handleChange(e)}
            className="shrink-0 my-auto w-4 aspect-square"
          />
          <label htmlFor="isEditDefault" className="my-auto">Use as default address</label>
        </section>
        <footer className="flex flex-col justify-center items-end px-16 mt-8 text-base font-semibold leading-6 max-md:pl-5 max-md:max-w-full">
          <div className="flex gap-4">
            <button
              type="button"
              className="justify-center px-12 py-3.5 text-red-700 whitespace-nowrap rounded-lg border border-red-700 border-solid max-md:px-5"
              onClick={() => setIsModalEditOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="justify-center px-8 py-3.5 text-white bg-red-700 rounded-lg max-md:px-5"
              onClick={() => handleUpdateSubmit()}
            >
              Update Address
            </button>
          </div>
        </footer>
        {/* </form> */}
      </div>
    </>
  )


  const onCloseEditIcon = () => {
    setIsModalEditOpen(false)
    setFormEditData({})
    setShow(false)
  }

  let defaultAddressShown = false;



  return (
    <>
      {/* <Layout> */}
      <main className={`flex flex-col justify-center rounded-xl max-w-${width} mx-auto`}>
        <header className="w-full  text-3xl font-semibold tracking-wider text-start text-neutral-900 max-md:max-w-full">
          My Addresses
        </header>
        <section className="flex gap-4 justify-end  mt-8 w-full leading-[150%] max-md:flex-wrap max-md:max-w-full">
          {/* {data?.map((addressData, index) => (
            addressData?.default_address === "0"  &&
            <div className="my-auto text-base font-semibold text-neutral-900">Default Address</div>
          ))
          } */}
          {data?.map((addressData, index) => {
            if (addressData?.default_address === "0" && !defaultAddressShown) {
              defaultAddressShown = true;
              return (
                <div key={index} className="my-auto text-base font-semibold text-neutral-900 w-full">
                  Default Address
                </div>
              );
            }
            return null;
          })}
          <button className="justify-center px-8 py-1.5 text-sm text-center text-white whitespace-nowrap bg-red-700 rounded-md font-[590] max-md:px-5"
            onClick={() => setIsModalOpen(true)}
          >
            Add
          </button>
        </section>
        {data?.length > 0 ?
          <div>

            {data?.sort((a, b) => a.default_address - b.default_address)?.map((addressData, index) => (

              addressData?.default_address === "0" ?
                <>
                  {/* <h3 className='font-bold mt-5'>Default Address</h3> */}
                  <AddressCard key={index}
                    {...addressData}
                    addressAddData={addressData}
                  />

                </>
                :
                <>
                  <h3 className='font-bold mt-5'>Saved Address</h3>
                  <div className='flex gap-5'>

                    <AddressCard key={index} {...addressData} addressAddData={addressData} />

                  </div>
                </>
            ))}
            {/* <div >
              <h3 className='font-bold mt-5'>Saved Address</h3>
              <div className='flex gap-5'>
                {addresses.map((addressData, index) => (
                  <AddressCard key={index} {...addressData} />
                ))}
              </div>
            </div> */}
          </div>
          :
          <></>
        }
      </main>
      {/* </Layout> */}
      {isModalOpen &&
        <AddressModalBox
          title={`Add Address`}
          modalContent={modalContent}
          modalWidth={`max-w-5xl`}
          onClose={() => setIsModalOpen(false)}
        />
      }

      {isModalEditOpen &&
        <AddressModalBox
          title={`Edit Address`}
          modalContent={modalEditContent}
          modalWidth={`max-w-5xl`}
          onClose={() => onCloseEditIcon()}
        />
      }
      <ToastContainer />
    </>
  )
}

export default MyAddressTabContent