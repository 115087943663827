import React from 'react'
import NavbarComponent from './NavbarComp'
import Footer from './Footer'
import { SearchProvider } from '../SearchContext';



const Layout = ({ children }) => {

    const width = "[1296px]";
    const navbarHeight = '150px';
    

    return (
        <>
            {/* <SearchProvider> */}
                <NavbarComponent />
                <div
                //  style={{ paddingTop: navbarHeight }}
                // style={{
                //     paddingTop: navbarHeight,
                //     minHeight: `calc(100vh - ${navbarHeight})`,
                //   }}
                 >
                    {children}
                </div>
                <Footer width={width} />
            {/* </SearchProvider> */}
        </>
    )
}

export default Layout  