import React from 'react';
import Layout from '../../../ReusableComp/Layout';
import ImgComp from '../../../ReusableComp/ImgComp';
import { width } from '../../../ReusableComp/Buttons';
import * as Images from '../../../../assets';

const NoApproved = () => {
  return (
    <Layout>
        <div className={`flex flex-col self-center px-5 mt-5 w-full max-w-${width} max-md:max-w-full mx-auto py-20`}>
          <div className='flex items-center justify-center  '>
            <ImgComp
              src={Images.view_shopping_cart}
              alt={''}
              className={'w-[30%] mx-auto '}
            />
          </div>
          <div className='text-center text-2xl my-5'>
            Account Status: <span className='mybzb_text_clr font-bold'>Pending</span>
          </div>
          <p className='text-neutral-400 text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
         
        </div>
      </Layout>
  )
}

export default NoApproved