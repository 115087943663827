import React, { useRef, useEffect, forwardRef } from 'react';
import * as Images from '../../../assets';

const ImgComp = forwardRef((props, ref) => (
    <img
        ref={ref}
        {...props}
        alt=''
        decoding='async'
        fetchPriority='high'
        loading='lazy'
    />
));

const HeadingTextImageWrapper = ({ children, text, className, textClassName ,alignTextWrapperClassName}) => {
    const textRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const textWidth = textRef.current.offsetWidth;
        const image = imageRef.current;

        // Adjust the image width based on the text width
        if (image) {
            image.style.width = `${textWidth}px`;
        }
    }, [text]);

    return (
        <>
            <div className={`flex flex-col w-fit  ${alignTextWrapperClassName}`}>
                {/* <h2 className="px-5 text-lg font-semibold tracking-wider  text-red-700 uppercase">
                    Basic and Exquisite
                </h2> */}
                <div
                    // className={`w-auto text-center ${className}`} 
                    className={className}
                >
                    <h1
                        // className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:text-4xl home_heading "
                        className={`mt-2 text-4xl font-bold capitalize text-neutral-900 max-md:text-3xl home_heading ${textClassName}`}
                        ref={textRef}>
                        {text}
                    </h1>
                    <ImgComp
                        src={Images.heading_underline}
                        alt={''}
                        className={'w-full max-w-full h-2'}
                        ref={imageRef}
                    />
                </div>
            </div>
        </>
    );
}

export default HeadingTextImageWrapper;
