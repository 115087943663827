import logo from './logo.svg';
import './App.css';
import Layout from './component/ReusableComp/Layout';
import { Route, Routes } from 'react-router-dom';
import Register from './component/UserAuth/Register';
import UserAuth from './component/UserAuth';
import Homepage from './component/Pages/Homepage';
import AddToCart from './component/Pages/AddToCart';
import Verification from './component/UserAuth/Verification';
import LocationVerification from './component/UserAuth/LocationVerification';
import UserProfile from './component/Pages/UserProfile';
import ProductDetail from './component/Pages/ProductDetail';
import CartContent from './component/Pages/AddToCart/EmptyCartContent';
import ListingPage from './component/Pages/ListingPage';
import ViewShoppingCart from './component/Pages/ShoppingCart/ViewShoppingCart';
import ShoppingCartContent from './component/Pages/ShoppingCart/ShoppingCartpage/ShoppingCartContent';
import ShoppingCartpage from './component/Pages/ShoppingCart/ShoppingCartpage';
import BecomeASeller from './component/UserAuth/BecomeASeller';
import NoApproved from './component/UserAuth/BecomeASeller/NoApproved';
import AllSellerpage from './component/Pages/Homepage/TopSeller/AllSellerpage';
import ListingContent from './component/Pages/ListingPage/ListingContent';
import AllCategoryListpage from './component/Pages/Homepage/PopularCategories/AllCategoryListpage';
import MyAddressTabContent from './component/Pages/UserProfile/UserProfileContent/MyAddressTabContent';
import MyWishlistTabContent from './component/Pages/UserProfile/UserProfileContent/MyWishlistTabContent';
import WebPage from './component/WebPage';
import { SearchProvider } from './component/ReusableComp/SearchContext';
import Navbar from './component/ReusableComp/Layout/NavbarComp/Navbar';
import WishlistPage from './component/Pages/WishlistPage';


function App() {
  return (
    <>
      <SearchProvider>
        <div className='hidden'>
        <Navbar/>
        </div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* <Route path="/register" element={<UserAuth />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/location" element={<LocationVerification />} />
          <Route path="/add_to_cart" element={<AddToCart />} />
          <Route path="/wishlist" element={<WishlistPage />} />

          {/* <Route path="/product_detail/*" element={<ProductDetail />} /> */}
          <Route path="product_detail" element={<ProductDetail />} >
            <Route index element={<ProductDetail />} />
            <Route path="product_detail" element={<ProductDetail />} />
            {/* <Route path="shoppingcart" element={<ShoppingCartpage />} /> */}

          </Route>
          <Route path="/seller-list" element={<AllSellerpage />} />
          <Route path="/category-list" element={<AllCategoryListpage />} />
          <Route path="/listing" element={<ListingPage />} />
          {/* <Route path="/all-listing" element={<ListingContent />} /> */}
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/withoutsign-shopping" element={<ViewShoppingCart />} />
          <Route path="/shoppingcart" element={<ShoppingCartpage />} />
          <Route path="/seller" element={<BecomeASeller />} />
          <Route path="/no_approved" element={<NoApproved />} />
          <Route path="myaddress" element={<MyAddressTabContent />} />
          <Route path="mywishlist" element={<MyWishlistTabContent />} />
          <Route
            path="/webpage"
            element={<WebPage
              title="MyBzb"
              description="A brief description of my website."

            />} />
        </Routes>
      </SearchProvider>
    </>
  );
}

export default App;
