import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import { fetchWithFormDataToken } from "../../../../../apiGeneralFunction";
import * as Images from '../../../../../assets'
import ImgComp from "../../../../ReusableComp/ImgComp";
import { AddressModalBox } from "../../../../ReusableComp/PaymentResponseModalBox";

const AccountTabContent = ({ }) => {

  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [clearedInputs, setClearedInputs] = useState({});
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');



  const [userFormData, setUserFormData] = useState({
    name: '',
    email: '',
    // phone: '',
    // home: '',
    // apartment: '',
    // landmark: '',
    // city: '',
    // state: '',
    // pincode: '',
    // address: ''
  });


  const handleFormChange = (e) => {

    const { name, email, phone, home, apartment, landmark, city, state, pincode, address, value } = e.target;


    setUserFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
      [email]: value,
      // [phone]: value,
      // [home]: value,
      // [apartment]: value,
      // [landmark]: value,
      // [city]: value,
      // [state]: value,
      // [pincode]: value,
      // [address]: value,
    }));

  };

  console.log("firstName", firstName);

  const name = userFormData?.name || ''
  const words = name?.split(" ");
  if (words?.length >= 3) {
    const first_Name = words[0];
    setFirstName(first_Name)
    const middleName = words[1];
    setMiddleName(middleName)
    const lastName = words[2];
    setLastName(lastName)
  } else {
    console.log("Not enough words to extract first, middle, and last names.");
  }

  useEffect(() => {


    const handleUserData = async () => {


      const user_id = localStorage?.getItem('user_data') || '';

      const userId = user_id ? JSON.parse(user_id) : {};

      try {
        const tokenValue = localStorage?.getItem('login api response token') || '';

        const formdata = new FormData();
        formdata.append("user_id", userId?.user_id);

        if (tokenValue) {
          try {
            const storedToken = JSON.parse(tokenValue);
            if (storedToken) {
              const result = await fetchWithFormDataToken('api/v1/get_profile', formdata, storedToken);
              // c
              if (result?.status === true) {
                setUserData(result?.data)

              }
              else {

              }
            }
          } catch (error) {


          }
        }


      } catch (error) {

      }



    };
    handleUserData();

  }, [])
  console.log("userFormData", userData?.profile_pic, selectedFileName, selectedFile);

  const handleFormSubmit = () => {

    console.log("firstName || userData?.firstname", firstName || userData?.firstname);

    const fetchAPI = async () => {
      const tokenValue = localStorage?.getItem('login api response token');
      const storedToken = JSON?.parse(tokenValue);

      const id = localStorage?.getItem('user_data');
      const user_data = JSON?.parse(id);

      try {
        const formdata = new FormData();
        if (selectedFile) {

          formdata.append("profile_pic", selectedFile, selectedFileName);
        } else if (userFormData.profile_pic) {

          formdata.append("profile_pic", userData?.profile_pic);
        }
        formdata.append("first_name", firstName || userData?.firstname);
        formdata.append("middlename", middleName || userData?.middlename);
        formdata.append("last_name", lastName || userData?.lastname);
        formdata.append("email", userFormData.email || userData?.email);
        formdata.append("user_id", user_data?.user_id);

        const result = await fetchWithFormDataToken('api/v1/update_profile', formdata, storedToken);
        console.log("edit profile data", result);
        if (result?.status === true) {

          toast.success(result?.message, {
            position: 'top-center',
            autoClose: 3000,

          });
          setShow(!show)

        }
        else {
          toast.error(result?.message, {
            position: 'top-center',
            autoClose: 3000,

          });
        }



      } catch (error) {
        console.error('Error fetching API:', error);
      }

    };
    fetchAPI();
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const phone = localStorage.getItem('register api response');
  const storedPhone = JSON.parse(phone);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    handleFile(file);
    const fileName = file;
    console.log("fileName", fileName);
    setSelectedFileName(fileName?.name)
  };


  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    console.log("hangleFile", file);
    setSelectedFile(file)

  };

  console.log("setSelectedFile", selectedFile);



  const ProfileImage = () => {

    return (
      <div
        className="flex flex-col p-6 mt-8 w-48  rounded-lg border border-orange-500 border-dashed terms_bg_clr max-md:px-5 max-md:max-w-full"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <label htmlFor="file-input" className="cursor-pointer">
          {image ? (
            <div className="text-center  mx-auto">
              <img
                loading="lazy"
                src={image}
                alt="Profile"
                className="self-center w-28 border-white border-solid aspect-square border-[6px] mx-auto"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                className="hidden"
                id="file-input"
              />

            </div>
          ) : (
            <div className="text-center  mx-auto">
              {/* <img
                                loading="lazy"
                                srcSet={Images.}
                                alt="Profile"
                             
                                className=" w-24 mx-auto"
                            /> */}

              <input
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                className="hidden"
                id="file-input"
              />
            </div>
          )}
          <div className="w-full text-end">
            <i className="fa fa-edit text-orange-500"></i>
          </div>
          {!image &&
            <>


              <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </div>
            </>
          }
        </label>
      </div>
    );

  }

  console.log("userFormData", userFormData);

  return (
    <>
      <main className="flex flex-col justify-center rounded-xl max-w-full w-[1296px]">
        <h1 className="w-full text-3xl font-bold text-left text-zinc-800 max-md:max-w-full ">
          {show ? `Edit Profile` : ` Account Information`}
        </h1>

        {show ?
          <ProfileImage />
          :
          <div
            // className="pr-6 mt-8 bg-white rounded-xl border border-gray-200 border-solid  max-md:max-w-full max-lg:p-5"
            className="pr-6 mt-8 bg-white rounded-xl border border-gray-200 border-solid max-lg:p-5"
          >

            <div
              //className="flex flex-row max-lg:flex-col gap-5 text-center items-center border border-green-500" 
              className="flex flex-row max-lg:flex-col gap-5 text-center items-center"
            >
              <div
                className="flex  border-e-2 border-gray-200 border-solid max-lg:border-0 max-lg:w-full w-[30%] justify-center  max-lg:border-0"
              >

                {userData?.profile_pic && /\.(png|webp|jpeg|jpg)$/.test(userData?.profile_pic) ? (
                  <ImgComp
                    className="w-48 h-48 rounded-full p-2"
                    src={userData?.profile_pic || ''}
                  />
                ) : (
                  <>
                    <ImgComp
                      className="w-48 h-48 p-2 "
                      src={Images.logo}
                    />

                  </>
                )}
              </div>
              <div
                className="flex flex-row w-full max-lg:w-full gap-5 max-lg:flex-col max-lg:gap-0 max-lg:mt-5  grid lg:grid-cols-2 xl:justify-between lg:justify-center items-center"
              >
                <div

                  className="my-auto flex-1 grow w-full text-left w-full max-lg:text-center"

                >
                  <div
                    className="text-xl font-medium text-zinc-800 h-5 "


                  >
                    {userData ? userData?.firstname + " " + userData?.middlename + " " + userData?.lastname : 'Full Name'}
                  </div>
                  <div

                    className="mt-3.5 text-base text-neutral-400 max-md:max-w-full flex-wrap text-wrap "
                    style={{ overflowWrap: 'break-word' }}

                  >
                    {userData?.email}
                  </div>
                </div>
                <div className="max-lg:mt-3">
                  <button
                    //onClick={handleFormSubmit}
                    onClick={() => setShow(true)}
                    className="justify-center px-4 py-2.5 text-white mybzb_bg_clr rounded-lg focus:outline-0 w-48 ">
                    Edit
                  </button>
                </div>
              </div>
            </div>

          </div>

        }


        <section className="flex flex-col justify-center p-5 mt-6 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:max-w-full">
          <h2 className="text-2xl font-medium text-left text-zinc-800 max-md:max-w-full">Personal Information</h2>
          <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap grid lg:grid-cols-2 grid-cols-1">

            <div className="flex flex-col max-md:max-w-full">
              <div className="text-sm text-neutral-400 max-md:max-w-full">Full Name</div>
              {show ?

                <input
                  type="text"
                  name="name"

                  value={userFormData?.name || ''}
                  onChange={(e) => handleFormChange(e)}

                  className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                />
                :
                <>
                  <div
                    className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                  >
                    {userData?.firstname + " " + userData?.middlename + " " + userData?.lastname}
                  </div>
                  {/* <input
                    type="text"
                    name="name"
                    value={userData?.firstname + "" + userData?.middlename + "" + userData?.lastname}
                    readOnly

                    className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                  /> */}
                </>
              }

            </div>
            <div className="flex flex-col max-md:max-w-full">
              <div className="text-sm text-neutral-400 max-md:max-w-full">Email Address</div>
              {show ?


                <input
                  type="email"
                  name="email"
                  value={userFormData?.email || ''}
                  onChange={(e) => handleFormChange(e)}

                  className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                />
                :
                <input
                  type="email"
                  name="email"
                  value={userData?.email || ''}
                  readOnly
                  className="focus:border-gray-200 focus:ring-0 focus:outline-0 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
                />
              }

            </div>

            <div className="flex flex-col max-md:max-w-full">
              <div className="text-sm text-neutral-400 max-md:max-w-full">Phone Number</div>
              <div className="h-12 px-3.5 py-3 mt-1.5 text-base bg-gray-100 rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full">
                {storedPhone}
              </div>
            </div>

          </div>
        </section>

        {/* <section className="flex flex-col justify-center p-5 mt-6 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:max-w-full">
        <h2 className="text-2xl font-medium text-left text-zinc-800 max-md:max-w-full">Address</h2>
        <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">

          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full" id="home">Home/Flat/Floor no.</div>
            <input
              type="text"
              name="home"
              id="home"
              // value={userFormData?.home || ''}
              onChange={handleFormChange}

              placeholder=''

              className="py-3 pr-4 pl-4 mt-1.5 text-base shrink-0 rounded-lg border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-md:max-w-full focus:border-gray-200 focus:ring-0 focus:outline-0"
            />

          </div>

          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full" id="apartment">Apartment/Road/Area</div>
            <input
              type="text"
              name="apartment"
              // value={userFormData?.apartment || ''}
              onChange={handleFormChange}

              className="h-12 focus:border-gray-200 focus:ring-0 focus:outline-0 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full">Landmark</div>
            <input
              type="text"
              name="landmark"
              // value={userFormData?.landmark || ''}
              onChange={handleFormChange}

              className="focus:ring-0 focus:outline-0 focus:border-gray-200 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full">City</div>
            <input
              type="text"
              name="city"
              // value={userFormData?.city || ''}
              onChange={handleFormChange}


              className="focus:ring-0 focus:outline-0 focus:border-gray-200 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full">State</div>
            <input
              type="text"
              name="state"
              // value={userFormData?.state || ''}
              onChange={handleFormChange}

              className="focus:ring-0 focus:outline-0 focus:border-gray-200 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col max-md:max-w-full">
            <div className="text-sm text-neutral-400 max-md:max-w-full">Pincode </div>
            <input
              type="number"
              name="pincode"
              // value={userFormData?.pincode || ''}
              onChange={handleFormChange}

              className="focus:ring-0 focus:outline-0 focus:border-gray-200 h-12 px-3.5 py-3 mt-1.5 text-base rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"
            />
          </div>
        </div>

        <div className="mt-6 text-sm text-neutral-400 max-md:max-w-full">Address Line 1</div>
        <input
          name="address"
          // value={userFormData?.address + "," + userData.state + "," + userData.zipcode || ''}

          onChange={handleFormChange}

          type="text"
          className="focus:ring-0 focus:outline-0 h-12 justify-center px-3.5 py-3 mt-1.5 text-base bg-white rounded-lg border border-gray-200 border-solid text-zinc-800 max-md:max-w-full"

        />

      </section> */}
        {show &&
          <div className="flex gap-5 pl-20 mt-6 text-base justify-end font-medium max-md:flex-wrap max-md:pl-5">

            <button
              onClick={() => handleFormSubmit()}
              // onClick={() => setShow(!show)}
              className="justify-center px-4 py-2.5 text-white mybzb_bg_clr rounded-lg focus:outline-0">
              Save Changes
            </button>
          </div>
        }
      </main>

    </>
  );
}

export default AccountTabContent