import React, { useEffect, useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";

function NavbarComponent() {

//     const [isScrolled, setIsScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsScrolled(true);
//       } else {
//         setIsScrolled(false);
//       }
//     };
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

    return (<div 
        className="flex flex-col w-full "
        // className={` ${isScrolled? 'fixed' : ''} flex flex-col w-full z-10`}
        // style={{
        //     position: isScrolled? 'fixed' : 'relative',
        //     top: isScrolled? 0 : '',
        //   }}
        > <Header /> <Navbar /> </div>);
}

export default NavbarComponent