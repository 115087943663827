import React from "react";
import * as Images from '../../../../../assets'
import ImgComp from "../../../ImgComp";

function SocialMediaIcons() {
    const socialMediaIcons = [{ src: "https://cdn.builder.io/api/v1/image/assets/TEMP/2dd432875c0d5f3d57cdd5170d6a6824a2e8cfd398433c3ef80cd53fe035e252?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 1" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ba01fd53fee55190d2612b00153906d1f773d091500cf7c0f5bd5ddeccde12d5?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 2" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8eb06097d9c6bb8cdbb5f7a8cb9831cb8f45b3d690966b8cc9adb025defefaca?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 3" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef76db6d738c49a10840817df0d456a250baa7d42656ca3a382f6f2c56b27163?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 4" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae752705b1e9e0089dcbb68c2d527bb8b2feabfb18c2fb46153cbec5d4324ced?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 5" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/5202f7e7e6c69e20dfb3d755e738b46fc8c3251f18df9c67a50ca8d77b3df76d?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 6" },];
    return (
        <div className="flex gap-3 my-auto items-center">
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.facebook.com/mybzb', '_blank');
                }}
                className='focus:outline-none'
            >
                <ImgComp
                    src={Images.header_fb}
                    className="w-4 aspect-square"
                />
            </button>

            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.facebook.com/mybzb', '_blank');
                }}
                className='focus:outline-none'
            >
                <ImgComp
                    src={Images.header_tw}
                    className="w-4 aspect-square"
                />
            </button>

            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://in.pinterest.com/my_bzb/', '_blank');
                }}
                className='focus:outline-none'
            >
                <ImgComp
                    src={Images.header_pin}
                    className="w-4 aspect-square"
                />
            </button>

            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.instagram.com/my_bzb?igsh=MWN6bmE3N3c5MmN1bQ==', '_blank');
                }}
                className='focus:outline-none'
            >
                <ImgComp
                    src={Images.header_insta}
                    className="w-4 aspect-square"
                />
            </button>
        


        </div>);
}
function LanguageSelector() {
    return (
        <div className="flex justify-center items-center px-1 gap-2">

            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6259d493f90b121a01a7ba38b5dd3a046a6dd87dd11b0082475854cf846be6cb?apiKey=8e5983036f284a13866aae97819cdb73&" alt="English language flag" className="object-cover absolute inset-0 size-full" /> */}
            <div>Eng</div>
            <i className="fa fa-angle-down"></i>
        </div>);
}
function CurrencySelector() {
    return (
        <div className="flex justify-center items-center px-1 gap-2">

            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6259d493f90b121a01a7ba38b5dd3a046a6dd87dd11b0082475854cf846be6cb?apiKey=8e5983036f284a13866aae97819cdb73&" alt="English language flag" className="object-cover absolute inset-0 size-full" /> */}
            <div>INR</div>
            <i className="fa fa-angle-down"></i>
        </div>
    );
}
function Header() {
    return (
    <header className="flex justify-center items-center px-16 py-3 w-full bg-red-700 shadow-sm max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between w-full max-w-[1296px] max-md:flex-wrap max-md:max-w-full">
            {/* <div className="my-auto text-sm leading-5 text-white"> Welcome to MyBzB{" "} </div> */}
            <div className="my-auto text-sm leading-5 text-white"> Gold Rate: 22 Carat ₹73,000/-{" "} </div>
            <div className="flex gap-5 justify-between py-1 items-center">
                <div className="flex gap-3 justify-center items-center">
                    <div className="text-sm leading-5 text-white">
                        Follow us:
                    </div>
                    <SocialMediaIcons />
                </div>
                <div className="flex gap-5 justify-between text-sm leading-5 text-white ">
                    <LanguageSelector /> <CurrencySelector />
                </div>
            </div>
        </div>
    </header>
    );
}

export default Header;