import React from 'react'

const StarRating = ({ value }) => {
    const Star = ({ filling }) => {
        if (typeof filling === 'undefined' || filling === 1) {
            return <i className="fa fa-star text-amber-500" />;
        }

        if (filling === 0) {
            return <i className="fa fa-star-o text-amber-500" />;
        }

        if (filling === 0.5) {
            return (
                <>
                {/* <div className="half-star"> */}
                    <i className="fa fa-star-half-o text-amber-500" />
                {/* </div> */}
                </>
            );
        }

        const width = filling * 100 + '%';
 
        return (
            <div className="star">
                <div className="fill" style={{ width }}>
                    <i className="fa fa-star text-amber-500" />
                </div>
                <div className="empty">
                    <i className="fa fa-star-o text-amber-500" />
                </div>
            </div>
        );
    };

    return (
        <div className="rating flex gap-1">
            {Array.from(Array(5)).map((_, index) => {
                const starSerialNumber = index + 1;

                if (starSerialNumber <= Math.floor(value)) {
                    return <Star key={starSerialNumber} filling={1} />;
                }

                if (starSerialNumber > Math.ceil(value)) {
                    return <Star key={starSerialNumber} filling={0} />;
                }

                const filling = value - index;

                return <Star key={starSerialNumber} filling={filling} />;
            })}
        </div>
    )
}

export default StarRating