import ImgComp from "../ImgComp";
import * as Images from '../../../assets';
import { Link } from "react-router-dom";

export const ThreeBreadcrumb = ({name2,name3,href2,href3}) => {
    function BreadcrumbItem({ to, text, isCurrent }) {
        return (
            <>
                <Link
                    to={to}
                    className={`${isCurrent ? "font-bold mybzb_text_clr " : "font-bold text-neutral-500"
                        }`}
                    aria-current={isCurrent ? "page" : undefined}
                >
                    {text}
                </Link>
                {!isCurrent && (
                    <>
                    {/* <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a0a3bfb142ddded4cbb3679af3fceb970a6108b17efc72cf7b5a60b23ac9af2c?apiKey=8e5983036f284a13866aae97819cdb73&"
                        alt=""
                        className="shrink-0 my-auto w-3 aspect-square"
                    /> */}
                    <i className="fa fa-angle-right text-base font-bold"></i>
                    </>
                )}
            </>
        );
    }
    return (
        <>
            <header className="self-stretch   py-3.5 text-sm bg-white border-b border-gray-200 border-solid text-neutral-400 max-md:px-5">
                <nav className="flex gap-2 items-center justify-start max-w-[1296px] mx-auto" aria-label="Breadcrumb">
                    <Link to={`/`} className="shrink-0 w-5 aspect-square">
                        <ImgComp
                            src={Images.icn_home_gray}
                            className="shrink-0 w-5 aspect-square"
                        />
                    
                    </Link>
                    <BreadcrumbItem to="/" text="Home" />
                    <BreadcrumbItem to={href2} text={name2} />
                    <BreadcrumbItem
                        // to="/product"
                        to={href3}
                        text={name3}
                        isCurrent={true}
                    />
                </nav>
            </header>

        </>
    )
}

export const TwoBreadcrumb = ({href3,name3}) => {
    const BreadcrumbItem = ({ to, text, isCurrent }) =>  {
        return (
            <>
                <Link
                    to={to}
                    className={`${isCurrent ? "font-bold mybzb_text_clr " : "font-bold text-neutral-500"
                        }`}
                    aria-current={isCurrent ? "page" : undefined}
                >
                    {text}
                </Link>
                {!isCurrent && (
                    <>
                    <i className="fa fa-angle-right text-base font-bold"></i>
                    </>
                )}
            </>
        );
    }
    return (
        <>
        <header className=" self-stretch py-3.5 text-sm bg-white border-b border-gray-200 border-solid text-neutral-400 max-md:px-5">
                <nav className="flex gap-2 items-center justify-start max-w-[1296px] mx-auto " aria-label="Breadcrumb">
                    <Link to={`/`} className="shrink-0 w-5 aspect-square">
                        <ImgComp
                            src={Images.icn_home_gray}
                            className="shrink-0 w-5 aspect-square"
                        />
                    
                    </Link>
                    <BreadcrumbItem to="/" text="Home" />
                    <BreadcrumbItem
                        // to="/product"
                        to={href3}
                        text={name3}
                        isCurrent={true}
                    />
                </nav>
            </header>
        </>
    )
}