import React from "react";
import * as Images from '../../../../../assets';
import { useNavigate } from "react-router-dom";
import { BgButton, width } from "../../../../ReusableComp/Buttons";
import ImgComp from "../../../../ReusableComp/ImgComp";
import HeadingTextImageWrapper from "../../../../ReusableComp/HeadingTextImageWrapper";
import StarRating from "../../../../ReusableComp/StarRating";


const SimilarProducts = ({ similarData }) => {

    console.log("similarData", similarData);


    const navigate = useNavigate();

    // const ProductCard = ({ product_image, name, price, selling_price, discount, total_review, total_rating, id, rating_avg }) => {

    //     // const Star = ({ filling }) => {
    //     //     if (typeof filling === 'undefined' || filling === 1) {
    //     //         return <i className="fa fa-star text-amber-500" />;
    //     //     }

    //     //     if (filling === 0) {
    //     //         return <i className="fa fa-star-o text-amber-500" />;
    //     //     }

    //     //     if (filling === 0.5) {
    //     //         return (
    //     //             <div className="half-star">
    //     //                 <i className="fa fa-star-half-o text-amber-500" />
    //     //             </div>
    //     //         );
    //     //     }

    //     //     const width = filling * 100 + '%';

    //     //     return (
    //     //         <div className="star">
    //     //             <div className="fill" style={{ width }}>
    //     //                 <i className="fa fa-star text-amber-500" />
    //     //             </div>
    //     //             <div className="empty">
    //     //                 <i className="fa fa-star-o text-amber-500" />
    //     //             </div>
    //     //         </div>
    //     //     );
    //     // };

    //     // const StarRating = ({ value }) => {
    //     //     return (
    //     //         <div className="rating">
    //     //             {Array.from(Array(5)).map((_, index) => {
    //     //                 const starSerialNumber = index + 1;

    //     //                 if (starSerialNumber <= Math.floor(value)) {
    //     //                     return <Star key={starSerialNumber} filling={1} />;
    //     //                 }

    //     //                 if (starSerialNumber > Math.ceil(value)) {
    //     //                     return <Star key={starSerialNumber} filling={0} />;
    //     //                 }

    //     //                 const filling = value - index;

    //     //                 return <Star key={starSerialNumber} filling={filling} />;
    //     //             })}
    //     //         </div>
    //     //     );
    //     // };

    //     const handleDetail = (id) => {
    //         console.log("handleDetail", id);
    //     }

    //     return (
    //         <>

    //             <div className="flex flex-col max-md:ml-0 max-md:w-full h-full"
    //                 onClick={() => navigate('/product_detail', { state: { id } })}
    //             >
    //                 <div className="relative flex  justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-200 border-solid shadow-2xl max-md:pl-5 max-md:mt-6">
    //                     {discount !== 0 && (
    //                         <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
    //                             {discount}

    //                         </div>
    //                     )}
    //                     <div 
    //                     className=" p-5 flex justify-between flex-col h-full"
    //                     >
    //                         <ImgComp
    //                             src={product_image}
    //                             alt={name}
    //                             // className={`max-sm:w-40 w-96 max-sm:mx-auto`}
    //                                className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60"
    //                         />
    //                          <div  className="  flex  flex-col h-full pt-0">
    //                         <div className="mt-2.5 text-xl font-semibold text-neutral-900 w-full md:text-base">
    //                             {name}
    //                         </div>
    //                         <div className="flex gap-2 justify-between mt-2">
    //                             <div className="text-lg text-neutral-900 md:text-base">
    //                                 <span className="line-through">
    //                                     {Number(price).toLocaleString('en-IN')}
    //                                     {/* {price} */}
    //                                 </span>{" "}
    //                                 <span className="line-through text-neutral-400">₹</span>{" "}
    //                                 <span className="font-[510] text-neutral-900">
    //                                     {/* {selling_price}  */}
    //                                     {Number(selling_price).toLocaleString('en-IN')}
    //                                     ₹
    //                                 </span> </div>
    //                             {/* <div className="text-base font-medium text-green-600">
    //                                 {discount} off
    //                             </div> */}
    //                         </div>
    //                         <div className="flex gap-2 justify-between mt-2">

    //                             <StarRating value={rating_avg} />

    //                             <div className="text-base leading-6 text-center text-neutral-400">
    //                                 ({total_rating}) reviews
    //                             </div>
    //                         </div>
    //                         </div>
    //                     </div>
    //                 </div>

    //             </div>

    //         </>
    //     );
    // }

    const ProductCard = ({ product_image, name, price, selling_price, discount, total_review, rating_avg, total_rating, id, discount_type }) => {

        const Star = ({ filling }) => {
            if (typeof filling === 'undefined' || filling === 1) {
                return <i className="fa fa-star text-amber-500" />;
            }

            if (filling === 0) {
                return <i className="fa fa-star-o text-amber-500" />;
            }

            if (filling === 0.5) {
                return (
                    <div className="half-star">
                        <i className="fa fa-star-half-o text-amber-500" />
                    </div>
                );
            }

            const width = filling * 100 + '%';

            return (
                <div className="star">
                    <div className="fill" style={{ width }}>
                        <i className="fa fa-star text-amber-500" />
                    </div>
                    <div className="empty">
                        <i className="fa fa-star-o text-amber-500" />
                    </div>
                </div>
            );
        };

        const StarRating = ({ value }) => {
            return (
                <div className="rating flex items-center gap-1">
                    {Array.from(Array(5)).map((_, index) => {
                        const starSerialNumber = index + 1;

                        if (starSerialNumber <= Math.floor(value)) {
                            return <Star key={starSerialNumber} filling={1} />;
                        }

                        if (starSerialNumber > Math.ceil(value)) {
                            return <Star key={starSerialNumber} filling={0} />;
                        }

                        const filling = value - index;
                        //  if (filling > 0 && filling < 1) {
                        //     return <Star key={starSerialNumber} filling={filling} />;
                        //   }

                        return <Star key={starSerialNumber} filling={filling} />;
                    })}
                </div>
            );
        };

        const handleDetail = (id) => {
            console.log("handleDetail", id);
        }

        return (
            <>
                <div
                    className="cursor-pointer"
                    onClick={() => navigate('/product_detail', { state: { id } })}
                //onClick={() => handleDetail(id)}
                >
                    <div className="flex flex-col  max-md:ml-0 max-md:w-full h-full">

                        <div
                            className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                        // className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                        >
                            {/* {(discount === 0 || discount === "0") && (
                                <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                                    {discount}

                                </div>
                            )} */}
                            {(discount > 0 || discount === "") && (
                                <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                                    {/* {data?.discount}% off */}
                                    {discount_type === 'flat' || discount_type === "" ? (
                                        <span>
                                            {discount < 0 ? '' : 'Flat ₹'}
                                            {Math.abs(discount)}
                                        </span>
                                    )
                                        :

                                        <span>

                                            {Math.abs(discount)}
                                            {discount < 0 ? '' : '%'}
                                        </span>
                                    }
                                </div>
                            )}

                            <div className=" p-5 flex  flex-col h-full max-sm:px-0">
                                <div
                                // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 xl:w-full xl:h-60 2xl:w-full  xl:h-60  max-sm:mx-auto `}
                                >
                                    <ImgComp
                                        src={product_image}
                                        alt={name}
                                        className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60  max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto"
                                    //   className={`max-sm:w-40 w-96 max-sm:mx-auto`}
                                    // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 xl:w-full xl:h-60 2xl:w-full  xl:h-60  max-sm:mx-auto`}
                                    />
                                </div>
                                <div className="  flex  flex-col h-full">
                                    <div className="mt-2.5 text-xl font-semibold text-neutral-900 w-full md:text-base">
                                        {name}
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">
                                        <div className="text-lg text-neutral-900 md:text-base">
                                            {price !== selling_price &&
                                                <>
                                                    <span className="line-through text-neutral-400">
                                                        {price?.toLocaleString('en-US')}
                                                    </span>{" "} &nbsp;
                                                    <span className="line-through text-neutral-400">₹</span>{" "}
                                                </>
                                            }
                                            <span className="font-[510] text-neutral-900">
                                                {selling_price?.toLocaleString('en-US')} ₹</span> </div>
                                    </div>
                                    <div className="flex gap-2 justify-between mt-2">

                                        <div className="w-fit">
                                            <StarRating value={rating_avg} />
                                        </div>

                                        {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                                            <div className="text-base leading-6 text-center text-neutral-400">
                                                ({total_rating}) reviews
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }


    return (
        <div className="flex flex-col py-20 max-md:px-0 ">
            <div className={`flex flex-col items-start self-center mt-5 w-full max-w-${width} max-md:max-w-full max-md:px-0`}>

                <div className="flex gap-5 justify-between mt-2 w-full max-md:flex-wrap items-center max-md:max-w-full">

                    <HeadingTextImageWrapper
                        text="Similar Products"
                        className="w-auto text-left"
                        textClassName='pe-10 ps-5'
                        alignTextWrapperClassName="text-left"
                    />
                    <div className="flex gap-3 my-auto" onClick={() => navigate('/listing')} >
                        <BgButton name={`See all`} />
                    </div>
                </div>
                <section className="self-stretch my-12 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div
                        // className="flex gap-5 max-md:flex-col grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  md:gap-3 sm:gap-3 xs:gap-3"
                        className="flex gap-5 max-md:flex-col grid max-2xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2  max-sm:grid-cols-1 grid-cols-4 max-sm:gap-3"
                    >
                        {/* <FeatureItem {...featureItem} /> */}
                        {/* {productCards.map((card, index) => (<ProductCard key={index} {...card} />))} */}
                        {similarData && similarData?.length > 0 && similarData.slice(0, 4).reverse().map((card, index) => (
                            <ProductCard key={index} {...card} />
                        ))}
                    </div>
                </section>
            </div>

        </div>
    );
}

export default SimilarProducts