import React, { useEffect, useState } from 'react'
import Layout from '../../ReusableComp/Layout'
import Categories from './Categories'
import TopSeller from './TopSeller'
import OurProducts from './OurProducts'
import BaseMaterial from './BaseMaterial'
import ExploreCollection from './ExploreCollection'
import PremiumProducts from './PremiumProducts'
import PopularProducts from './PopularProducts'
import TrendingProducts from './TrendingProducts'
import HomeBanner from './HomeBanner'
import PopularCategories from './PopularCategories'
import { fetchWithFormDataToken } from '../../../apiGeneralFunction'
import { useNavigate } from 'react-router-dom'
import CategoriesSlider from './CategoriesSlider'



const Homepage = () => {


  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [feature,setFeature] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/');
      // return;
    }

    const abortController = new AbortController();

    try {


      const formdata = new FormData();
      formdata.append("device_type", "web");

      const result = await fetchWithFormDataToken(`api/v1/get_home_data`, formdata, abortController, null);
      if (result?.status === true) {
        setData(result?.data)

      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const width = "[1296px]"

  console.log("home data", data?.PromotionalsData[0]);


  return (
    <>
      <Layout>
        <HomeBanner data={data?.banners}/>
        <CategoriesSlider/>
        <OurProducts width={width} data={data?.PromotionalsData[0]?.product || ''} key={data?.PromotionalsData[0]?.id} title={data?.PromotionalsData[0]?.title}/>
        <BaseMaterial width={width} />
        <ExploreCollection width={width} data={data?.PromotionalsData[2]?.product || ''}  key={data?.PromotionalsData[2]?.id}  title={data?.PromotionalsData[2]?.title}/>
        <PremiumProducts width={width} />
        <PopularProducts width={width}  data={data?.PromotionalsData[1]?.product || ''}  key={data?.PromotionalsData[1]?.id} 
         title={data?.PromotionalsData[1]?.title}
        />
        {/* <TrendingProducts width={width} /> */}
                {/* <TopSeller width={width} data={data?.topSeller}/> */}
                        {/* <Categories /> */}
        {/* <PopularCategories width={width} data={data?.topCategory}/> */}
      </Layout>
    </>
  )
}

export default Homepage