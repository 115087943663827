import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import AccountTabContent from './AccountTabContent';
import MyOrderTabContent from './MyOrderTabContent';
import MyWishlistTabContent from './MyWishlistTabContent';
import MyAddressTabContent from './MyAddressTabContent';
import NotificationTabContent from './NotificationTabContent';
import { TwoBreadcrumb } from '../../../ReusableComp/Breadcrumb';


const UserProfileContent = () => {
    const [activeTab, setActiveTab] = useState('Account Info');
    const [settingActiveTab, setSettingActiveTab] = useState('Profile');
    const [showContent, setShowContent] = useState(true);
    const [showTabs, setShowTabs] = useState(true);


    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.state?.openAddressTab) {
            setActiveTab('My Addresses');
        }


    }, [location]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (window.innerWidth <= 767) {
            setShowContent(true);
            setShowTabs(false);
        } else {
            setShowContent(true);
            setShowTabs(true)
        }

    };

    const handleSettingTabClick = (tabName) => {
        setSettingActiveTab(tabName);
    };

    const SubMenuItem = ({ children, className, onClick }) => (
        <div
            onClick={onClick}
            className={`text-base font-medium text-neutral-500 hover:text-orange-500 cursor-pointer ${className}`}>
            {children}
        </div>
    );

    const tabContent = {
        'Account Info': (
            <div>

                <AccountTabContent />

            </div>
        ),

        'My Orders': (
            <div
            >
                <MyOrderTabContent />


            </div>
        ),
        'My Wishlist': (
            <div
            >
                <MyWishlistTabContent />

            </div>
        ),
        'My Addresses': (
            <div
            >
                <MyAddressTabContent />


            </div>
        ),


        'Change Language': (
            <div className="text-center flex items-center justify-center w-full h-full">

                <div className="text-5xl">Coming Soon ...</div>
            </div>
        ),
        'Notification': (
            <div
            >
                <NotificationTabContent />

            </div>
        ),
    };


    const AccountInfoLink = ({ children, className, onClick }) => (
        <div className={`justify-center items-start lg:p-4 xl:p-4 2xl:p-4 max-sm:py-2  rounded-lg md:p-1 md:text-sm max-md:pr-5 cursor-pointer ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    );


    const breadcrumbNames = {
        'Account Info': 'Account Info',
        'My Orders': 'My Orders',
        'My Wishlist': 'My Wishlist',
        'My Addresses': 'My Addresses',
        'Change Language': 'Change Language',
        'Notification': 'Notification',
    };

    // useEffect(() => {
    //     if (window.innerWidth <= 767) {
    //         if (location.state?.fromNavbar) {
    //             setShowTabs(true);
    //             setShowContent(false);
    //         }
    //     }
    // }, [location])

    const handleResize = () => {

        // if (location?.state?.fromNavbar !== "" || location?.state?.fromNavbar !== null) {
            if (location?.state?.fromNavbar !== null) {
            if (window.innerWidth <= 767 || location?.state?.fromNavbar) {
                setShowTabs(true)
                setShowContent(false);
            }
            else {
                setShowContent(true);
                setShowTabs(true)
            }
        }
        // }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location]);


    return (
        <>
            <TwoBreadcrumb
                // name2={`Ring`}
                // href2={`/`}
                name3={breadcrumbNames[activeTab]}
                href3={`/`}
            />
            <div className=" flex  justify-center items-center px-16 py-20 max-lg:pt-0 max-lg:pb-10 bg-white max-md:px-5  max-md:pt-10">

                <div className=" p-10 max-w-full bg-white rounded-3xl shadow-2xl  w-[1296px] max-md:px-5 md:px-4">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
                        {showTabs && (
                            <aside className="flex flex-col w-[21%] max-md:ml-0 md:w-[25%] max-md:w-full">
                                <nav className="flex flex-col text-base text-center text-neutral-400  gap-5 min-lg:text-xs  ">

                                    <AccountInfoLink
                                        className={activeTab === 'Account Info' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('Account Info')}
                                    >
                                        Account Info
                                    </AccountInfoLink>


                                    <AccountInfoLink
                                        className={activeTab === 'My Orders' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('My Orders')}
                                    >
                                        My Orders
                                    </AccountInfoLink>
                                    <AccountInfoLink
                                        className={activeTab === 'My Wishlist' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('My Wishlist')}
                                    >
                                        My Wishlist
                                    </AccountInfoLink>
                                    <AccountInfoLink
                                        className={activeTab === 'My Addresses' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('My Addresses')}
                                    >
                                        My Addresses
                                    </AccountInfoLink>
                                    <AccountInfoLink
                                        className={activeTab === 'Change Language' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('Change Language')}
                                    >
                                        Change Language
                                    </AccountInfoLink>
                                    <AccountInfoLink
                                        className={activeTab === 'Notification' ? "font-medium mybzb_text_clr mybzb_bg_pinkclr font-bold" : "border border-gray-200 border-solid"}
                                        onClick={() => handleTabClick('Notification')}
                                    >
                                        Notification
                                    </AccountInfoLink>

                                </nav>
                            </aside>
                        )
                        }
                        {showContent && (
                            <main
                                className="flex flex-col ml-5 w-[79%] md:w-[75%] max-md:ml-0 max-md:w-full max-md:pt-5"
                            >
                                {tabContent[activeTab]}
                            </main>
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfileContent 