import React from 'react';
import Select from 'react-select';


export const InputWithAsterisk = ({ value, onChange, labelName, type, placeholder, hintName ,name}) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3'>
                <label className='text-black font-bold'>
                    {labelName}
                    <span className='mybzb_text_clr'> *</span>
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='mt-3 w-full border border-gray-200 rounded-lg text-sm p-3 h-11 '
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                />

            </div>
        </>
    )
}


export const FormInput = ({ label, placeholder, id, value, onChange, name }) => {



    return (
      <div className="flex flex-col flex-1 text-neutral-400">
        <label htmlFor={id} className="sr-only">
          {label}
        </label>
        <div className='text-start'>{label}</div>
        <input
          type="text"
          //id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          className="mt-2 rounded-md border border-gray-200 border-solid text-black"

        //aria-label={label}
        />
      </div>
    )
  };

export const InputWithoutAsterisk = ({ value, onChange, labelName, type, placeholder,hintName,name }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3'>
                <label className='text-black font-bold'>
                    {labelName}
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='mt-3 w-full border border-gray-200 rounded-lg'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                />

            </div>
        </>
    )
}

export const InputWithoutLabel = ({ value, onChange, type, placeholder, name }) => {
    return (
        <>
            <input
                className='mt-3 w-full border border-gray-200 rounded-lg text-sm p-3 text-black'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={type}
                name={name}
            />
        </>
    )
}

export const TextareaWithoutLabel = ({ value, onChange, placeholder,name }) => {
    return (
        <>
            <textarea
                className='mt-3 w-full border border-gray-200 rounded-lg h-28 text-sm p-3'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
            >

            </textarea>
        </>
    )
}

export const DropdownWithAsterisk = ({ value, onChange, labelName, id, placeholder, options }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3'>
                <label className='text-black font-bold'>
                    {labelName}
                    <span className='mybzb_text_clr'> *</span>
                </label>
                <select
                    id={id}
                    value={value}
                    onChange={onChange}
                    className="block w-full p-2 mt-3 text-base h-12 text-neutral-500 border border-gray-300 rounded-lg bg-white"
                >
                    <option value="" disabled className="bg-white">{placeholder}</option>
                    {options?.length > 0 ?
                        options?.map((item) => (
                            <option
                                key={item?.id}
                                value={item?.id}
                                className="bg-white"
                            >
                                {item?.name}
                            </option>
                        ))
                        :
                        <></>
                    }
                </select>

            </div>
        </>
    )
}

export const CustomDropdown = ({ value, onChange, options, placeholder, labelName, asterisk }) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#FDEDF1' : state.isFocused ? 'lightgray' : 'white',
            color: 'black',
            padding: 10,
        }),
        control: (provided) => ({
            ...provided,
            marginTop: '3px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            padding: '6px 10px'
        }),
    };

    const formattedOptions = options.map(item => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <div className='flex flex-col items-start '>
            <label className='text-neutral-400 font-medium'>
                {labelName}
                <span className='mybzb_text_clr'> {asterisk}</span>
            </label>
            <Select
                value={options.find(option => option.value === value)}
                // onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : '')}
                onChange={(selectedOption) => onChange({
                    id: selectedOption.value,
                    label: selectedOption.label,
                  })}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                className='w-full mt-3 '
            />
        </div>
    );
};

export const InputWithoutBold = ({ value, onChange, labelName, type, placeholder,hintName,name }) => {
    return (
        <>
            <div className='flex flex-col items-start'>
                <label className='text-neutral-400 '>
                    {labelName}
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='w-full border border-gray-200 rounded-lg'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                />

            </div>
        </>
    )
}
