import React from "react";
import * as Images from '../../../../assets';
import ImgComp from "../../../ReusableComp/ImgComp";
import HeadingTextImageWrapper from "../../../ReusableComp/HeadingTextImageWrapper";
import { BgButton } from "../../../ReusableComp/Buttons";
import { useNavigate } from "react-router-dom";


const PopularProducts = ({ data, title, width }) => {

    console.log("PopularProducts", data);
    const navigate = useNavigate();


    const ProductCard = ({ product_image, name, price, selling_price, discount, total_review, total_rating, id, rating_avg, discount_type }) => {

        const Star = ({ filling }) => {
            if (typeof filling === 'undefined' || filling === 1) {
                return <i className="fa fa-star text-amber-500" />;
            }

            if (filling === 0) {
                return <i className="fa fa-star-o text-amber-500" />;
            }

            if (filling === 0.5) {
                return (
                    <div className="half-star">
                        <i className="fa fa-star-half-o text-amber-500" />
                    </div>
                );
            }

            const width = filling * 100 + '%';

            return (
                <div className="star">
                    <div className="fill" style={{ width }}>
                        <i className="fa fa-star text-amber-500" />
                    </div>
                    <div className="empty">
                        <i className="fa fa-star-o text-amber-500" />
                    </div>
                </div>
            );
        };

        const StarRating = ({ value }) => {
            return (
                <div className="rating flex items-center gap-1">
                    {Array.from(Array(5)).map((_, index) => {
                        const starSerialNumber = index + 1;

                        if (starSerialNumber <= Math.floor(value)) {
                            return <Star key={starSerialNumber} filling={1} />;
                        }

                        if (starSerialNumber > Math.ceil(value)) {
                            return <Star key={starSerialNumber} filling={0} />;
                        }

                        const filling = value - index;
                        //  if (filling > 0 && filling < 1) {
                        //     return <Star key={starSerialNumber} filling={filling} />;
                        //   }

                        return <Star key={starSerialNumber} filling={filling} />;
                    })}
                </div>
            );
        };

        return (
            <div className="flex flex-col   max-md:ml-0 max-md:w-full h-full cursor-pointer "
                onClick={() => navigate('/product_detail', { state: { id } })}
            >
                <div
                    className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 "
                // className="relative flex justify-between flex-col grow mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid hover:shadow-2xl hover:border-0 max-md:pl-5 max-md:mt-6"
                >
                    {/* {discount !== 0 && (
                        <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                            {discount}
                        </div>
                    )} */}
                    {(discount > 0 || discount === "") && (
                        <div className="text-base font-medium bg-red-700 text-white absolute px-3 py-1 right-0 rounded-bl-lg">
                            {/* {data?.discount}% off */}
                            {discount_type === 'flat' || discount_type === "" ? (
                                <span>
                                    {discount < 0 ? '' : 'Flat ₹'}
                                    {Math.abs(discount)}
                                </span>
                            )
                                :

                                <span>

                                    {Math.abs(discount)}
                                    {discount < 0 ? '' : '%'}
                                </span>
                            }
                        </div>
                    )}

                    <div className=" p-5 flex justify-between flex-col h-full">
                        <div
                        // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto xl:w-full xl: xl:h-60 2xl:w-full 2xl: xl:h-60`}
                        >
                            <ImgComp
                                src={product_image}
                                alt={name}
                                className="object-contain xl:w-full  xl:h-60 2xl:w-full 2xl:h-60  max-sm:w-40 md:w-48 md:h-48 lg:w-48 lg:h-40 max-sm:mx-auto"
                            // className={`max-md:w-40 w-96 max-md:mx-auto`}
                            // className={`max-sm:w-40 md:w-48 lg:w-48 lg:h-40 max-sm:mx-auto xl:w-full xl: xl:h-60 2xl:w-full 2xl: xl:h-60`}
                            />
                        </div>
                        <div className=" flex  flex-col h-full pt-0">
                            <h3 className="mt-2.5 text-xl font-semibold text-ellipsis text-neutral-900 md:text-base">
                                {name}
                            </h3>
                            <div className="flex gap-2 justify-between mt-2">
                                <div className="text-lg text-neutral-900 md:text-base">
                                    {price !== selling_price &&
                                        <>
                                            <span className="line-through text-neutral-400">
                                                {price?.toLocaleString('en-US')}
                                            </span>{" "} &nbsp;
                                            <span className="line-through text-neutral-400">₹</span>{" "}
                                        </>
                                    }
                                    <span className="font-[510] text-neutral-900">
                                        {selling_price?.toLocaleString('en-US')} ₹</span> </div>

                            </div>
                            <div className="flex gap-2 justify-between mt-2">

                                {/* <StarRating value={total_review} /> */}
                                <StarRating value={rating_avg} />

                                {(total_rating !== 0 || total_rating === "0" || total_rating === "") && (

                                    <div className="text-base leading-6 text-center text-neutral-400">
                                        ({total_rating}) reviews
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col py-10 bg-rose-50 max-sm:pb-0">
            <div className={`flex flex-col items-center self-center px-5 w-full max-w-${width} max-md:max-w-full`}>
                {/* <div className="text-lg font-semibold tracking-wider text-center text-red-700 uppercase">
                    Basic and Exquisite
                </div>
                <h1 className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:text-4xl">
                    Popular Products
                </h1> */}
                <div className="flex gap-5 justify-between w-full max-md:flex-wrap items-center max-md:max-w-full">

                    <HeadingTextImageWrapper
                        // text="Popular Items"
                        text={title}
                        className="w-auto text-left"
                        textClassName='pe-10 ps-5'
                        alignTextWrapperClassName="text-left"
                    />
                    <div className="flex gap-3 my-auto" onClick={() => navigate('/listing')}>

                        <BgButton name={` See all`} />
                    </div>
                </div>
                {/* <HeadingTextImageWrapper
                    text="Popular Items"
                    className="w-auto text-center"
                    textClassName='px-10'
                    alignTextWrapperClassName="text-center"
                /> */}
                <section className="self-stretch mt-12 mb-12 max-md:mt-10 max-md:max-w-full">
                    <div
                        className="flex gap-5 max-md:flex-col grid max-2xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 grid-cols-4 md:gap-3 max-sm:gap-8"
                    // className="flex gap-5 max-md:flex-col grid lg:grid-cols-4 md:grid-cols-3 max-sm:grid-cols-1 md:gap-3 max-sm:gap-5"
                    >
                        {data && data?.length > 0 && data.slice(0, 4).reverse().map((card, index) => (
                            <ProductCard key={index} {...card} />
                        ))}

                    </div>
                </section>
                {/* <div className="" onClick={() => navigate('/listing')} >
                    <BgButton name={`See all`} />
                </div> */}
            </div>
            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a45a056bfda675e1e4360035dcc1a453888d96e53828f720f06476fe464ed35c?apiKey=8e5983036f284a13866aae97819cdb73&" alt="" className="mt-10 max-w-full aspect-[3.13] w-[183px] max-md:mt-10" /> */}
        </div>
    );
}

export default PopularProducts