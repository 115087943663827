import React, { useEffect, useState } from "react";
import Layout from "../../ReusableComp/Layout";
import * as Images from '../../../assets';
import { post, postFormData } from "../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../ReusableComp/ImgComp';
import { useNavigate } from "react-router-dom";

const Register = ({ setShowVerify, setShowLocation }) => {

    // const [formData, setFormData] = useState(new FormData());
    const [number, setNumber] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async () => {

        if (number?.length > 10) {
            setTimeout(() => {
                toast.error("Phone number should not be more than 10 digits", {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }, 1000);
            return;
        }
        else {
            try {

                const formdata = new FormData();
                formdata.append("phone", number);
                formdata.append("country_code", "+91");

                const response = await postFormData('api/v1/sendotp', formdata);
                if (response.status === true) {
                    localStorage.setItem("register api response", JSON.stringify(response?.data?.phone))
                    setTimeout(() => {
                        toast.success(response?.message || '', {
                            position: 'top-center',
                            autoClose: 3000,
                        });
                    }, 1000);
                    navigate('/verification')
                    setShowVerify(true)
                    setShowLocation(false)

                }
                else {
                    setTimeout(() => {
                        toast.error(response.message, {
                            position: 'top-center',
                            autoClose: 3000,
                        });
                    }, 1000);
                }
            } catch (error) {
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // formData.set(name, value);
    };
    const data = [{ id: 1, title: "Privacy Policy", },
    { id: 2, title: "Terms & Conditions", },];

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            handleSubmit();
        }
    };

    useEffect(() => {
        const inputElement = document.getElementById('phone');
        if (inputElement) {
            inputElement.addEventListener('keypress', handleKeyPress);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keypress', handleKeyPress);
            }
        };
    }, []);

    return (

        <Layout>
            <main className="flex justify-center items-center px-16 py-20 bg-white max-lg:px-5">
                <div className="max-w-full rounded-2xl shadow-2xl w-[1296px] ">
                    <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 ">
                        <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full ">
                            <section
                                className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-20 py-12 w-full bg-white max-lg:px-14 max-sm:px-5 max-lg:max-w-full"
                            // className="rounded-2xl flex flex-col grow justify-between items-center self-stretch px-10 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full"
                            >
                                {/* <div className="px-10"> */}
                                <div className="w-full">
                                    <img loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/33182bb4a34d51254c94257e2b3e52809ccb4863687eb854d868e5b9fc8a3488?apiKey=8e5983036f284a13866aae97819cdb73&"
                                        alt="Company logo" className="max-w-full aspect-[0.99] w-[114px] mx-auto" />
                                </div>
                                <h1 className="mt-10 text-3xl leading-10 text-center text-red-700">
                                    <span className="">Welcome to </span> <span className="font-bold text-red-700">My BzB</span> </h1>
                                <p className="self-stretch mt-3 text-sm text-center text-neutral-400 max-lg:max-w-full">
                                    India's First My Bharatiya Zaveri Bazar Retail Culture Jewellery Store </p>
                                <div
                                    className="pt-11 self-stretch "
                                >
                                    <label htmlFor="emailOrMobile" className="self-stretch   text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                        Mobile no.
                                        <span className="text-red-800">*</span>
                                    </label>
                                    <div className="flex gap-1.5 items-start py-px  pl-5 mt-3.5 max-w-full text-base  rounded-lg border border-gray-200 border-solid text-zinc-800 w-full ">
                                        <div className="flex  my-auto pe-3">
                                            <div className="flex ">
                                                <img loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/300a3765c34b6b6d185f8430266221867c7fbc1bc54175e41b4ca73b19a1f5e5?apiKey=8e5983036f284a13866aae97819cdb73&" alt="India flag" className="shrink-0 my-auto w-6 aspect-[1.33]" />

                                            </div>
                                            <div className="flex gap-1">
                                                <div className="ps-2">+91</div>
                                                <img loading="lazy"
                                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccbeddf94b3fe91b18ab065a70628d22f9f5bb3a97ce0bd54efa6d0e706ff1fb?apiKey=8e5983036f284a13866aae97819cdb73&"
                                                    alt="Down arrow" className="shrink-0 my-auto w-4 aspect-square" />
                                            </div>

                                        </div>
                                        <div className="w-full" >
                                            <input
                                                id="phone"
                                                type="text"
                                                name="phone"
                                                // onChange={handleInputChange}
                                                value={number}
                                                onChange={(e) => setNumber(e.target.value)}
                                                // onChange={onChange}
                                                required
                                                inputMode="numeric"
                                                pattern="[0-9]{1,10}"
                                                maxLength={10}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                        e.preventDefault();
                                                    }
                                                    handleKeyPress(e);
                                                }}
                                                className=" h-14 border-l-1 border-y-0 rounded-e-lg border border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                            />
                                        </div>
                                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e41831aebca18a2cdfc1eb182fddf221e908f0eb08e9c1d88a66811c552d79b?apiKey=8e5983036f284a13866aae97819cdb73&"
                                    alt=""
                                    className="shrink-0 self-start w-px border border-gray-200 border-solid aspect-[0.02] stroke-[1px] stroke-gray-200" /> */}
                                    </div>
                                </div>
                                <div className="flex flex-col px-20 pb-2 mt-6 max-w-full text-center lg:px-0">
                                    <button
                                        onClick={handleSubmit}
                                        className="justify-center items-center px-20 py-4 text-lg font-medium bg-red-700 text-white rounded max-sm:px-5 max-lg:py-2" >
                                        Get OTP
                                    </button>
                                    <button className="flex gap-2  items-center self-center px-px mt-4 text-sm font-semibold leading-5 text-red-700 whitespace-nowrap text-red-700 ">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => navigate('/')}>Skip</div>
                                        <i className="fa fa-arrow-right text-red-800"></i>
                                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/914c64e8caf43b02b624af135858e4016551e9ff9557352852c1ea5c6d60f43a?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Right arrow" className="shrink-0 w-6 aspect-square" /> */}
                                    </button>
                                </div>
                                <ul className="flex gap-5 focus:border-zinc-50 justify-between self-stretch mt-10 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full"> {data.map((item) => (<li key={item.id}>{item.title}</li>))}
                                </ul>
                                <span className="text-red-800 text-sm mt-5">Powered by Headway Business Solutions LLP</span>
                                {/* </div> */}
                            </section>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-lg:ml-0 max-lg:w-full hidden sm:block">
                            {/* <div
                                 className="flex flex-col grow justify-center w-full bg-rose-50 max-lg:max-w-full"
                                className="flex flex-col grow justify-center w-full register_bg_clr max-lg:max-w-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none"
                            >
                                <div
                                    className="flex flex-col justify-center max-lg:max-w-full "
                                > */}

                            <ImgComp
                                src={Images.register}
                                srcSet={Images.register}
                                alt={'register'}
                                className={'h-full w-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none '}

                            />
                            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a5f176abe95861c5d60f680f238bae56000737b37db83dc2ed867c8b0af22e1?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Decorative background" className="w-full aspect-[1.01] fill-red-100 max-lg:max-w-full" /> */}
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </main>
        </Layout>



    );
}

export default Register