import React from "react";
import HeadingTextImageWrapper from "../../../ReusableComp/HeadingTextImageWrapper";

const BaseMaterial = () => {
  return (
    <section className="flex justify-center items-center px-16 py-20 max-md:px-5">
      <article className="flex flex-col items-center max-w-full w-[1104px]">
        {/* <h2 className="text-lg font-semibold tracking-wider text-center text-red-700 uppercase">
          Basic and Exquisite
        </h2>
        <h1 className="mt-2 text-5xl font-medium capitalize text-neutral-900 max-md:text-4xl">
          Base material
        </h1> */}
        <HeadingTextImageWrapper
          text="Base material"
          className="w-auto text-center"
          textClassName='px-10'
          alignTextWrapperClassName="text-center"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0b2e3cee26d6a80d33ec962c1e4f140d89145fb8542c9e5c33440b4a1f39091?apiKey=8e5983036f284a13866aae97819cdb73&"
          alt="Base material"
          // className="self-stretch mt-12 w-full aspect-[1.85] max-md:mt-10 max-md:max-w-full"
          className=" mt-12 w-[80%] max-md:mt-10 max-md:max-w-full"
        />
      </article>
    </section>
  );
}

export default BaseMaterial