import React, { useEffect, useState } from 'react'
import Layout from '../../../ReusableComp/Layout'
import ShoppingCartContent from './ShoppingCartContent'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';

const ShoppingCartpage = () => {

    // const location = useLocation();
    // // const { id } = location?.state;
    // const cartId = location?.state?.cartId;
    // console.log("ProductDetail cartId", cartId);

    // const navigate = useNavigate();


    // const [data, setData] = useState(null);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    // useEffect(() => {
    //     fetchData();
    // }, [])

    // console.log("cartId?.variation[0]?.data[0]?.value",cartId?.variation[0]?.data[0],cartId?.variation[1]?.data[0]);

    // let weight, size;
    // cartId?.variation?.forEach((variation) => {
    //     if (variation.name === "weight") {
    //       weight = variation.data[0];
    //     } else if (variation.name === "size") {
    //       size = variation.data[0];
    //     }
    //   });

    //   console.log("Weight:", weight,size);
    //   console.log("Size:", size);

    // const fetchData = async () => {

    //     const tokenValue = localStorage?.getItem('login api response token');

    //     const user_id = localStorage?.getItem('user_data');
    //     const userId = JSON.parse(user_id);

    //     const storedToken = JSON?.parse(tokenValue);
    //     if (!storedToken) {
    //         navigate('/register');
    //         // return;
    //     }

    //     const abortController = new AbortController();

    //     try {

    //         const formdata = new FormData();
    //         formdata.append("user_id",  userId?.user_id);
    //         formdata.append("seller_id",cartId?.seller_id);
    //         formdata.append("product_id", cartId?.id);
    //         formdata.append("quantity", "1");
    //         formdata.append("size", size || 0);
    //         formdata.append("weight", weight || '');

    //         const result = await fetchWithFormDataToken(`api/v1/product_add_to_cart`, formdata, abortController, null);
    //         if (result?.status === true) {
    //             setData(result?.data)

    //         }
    //         else {

    //         }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // };

    // console.log("data ShoppingCartpage",data);

    return (
        <>
            <Layout>
                <ShoppingCartContent />
            </Layout>
        </>
    )
}

export default ShoppingCartpage