import React, { useEffect, useState } from 'react';
import { useDrag } from '@use-gesture/react';
import HeadingTextImageWrapper from '../../../ReusableComp/HeadingTextImageWrapper';
import { width } from '../../../ReusableComp/Buttons';
// import '../PopularCategories/popularcat.css';
import { useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { useGesture } from 'react-use-gesture';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';


const slideWidth = 10;

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms));


const createItem = (position, idx, items) => {
    const item = {
        styles: {
            transform: `translateX(${position * slideWidth}rem)`,
        },
        player: items[idx],
    };

    switch (position) {
        case items.length / 2 - 1:
        case items.length / 2 + 1:
            item.styles = {
                ...item.styles,
                //  filter: 'grayscale(1)' 
            };
            break;
        case items.length / 2:
            break;
        default:
            item.styles = {
                ...item.styles,
                // opacity: 0 
            };
            break;
    }

    return item;
};


const CategoriesSlider = () => {

    const [index, setIndex] = useState(0);

    const navigate = useNavigate();

    const [items, setItems] = useState([]);
    const [activeIdx, setActiveIdx] = useState(0);
    const [isTicking, setIsTicking] = useState(false);
    const [isHovered, setHovered] = useState(false);


    useEffect(() => {

        const fetchData = async () => {

            const tokenValue = localStorage?.getItem('login api response token');

            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/');
                // return;
            }

            const abortController = new AbortController();

            try {


                const formdata = new FormData();
                formdata.append("device_type", "Web");

                const result = await fetchWithFormDataToken(`api/v1/get_home_data`, formdata, abortController, null);
                if (result?.status === true) {
                    const fetchedItems = result?.data?.topCategory;
                    setItems([...fetchedItems, ...fetchedItems]);

                }
                else {

                }
            } catch (error) {

            }
            finally {

                abortController.abort();
            }
        };

        fetchData();
    }, []);

    const prevClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setActiveIdx((prev) => (prev - jump + items.length) % items.length);
        }
    };

    const nextClick = (jump = 1) => {
        // if (!isTicking) {
        //     setIsTicking(true);
        //     setActiveIdx((prev) => (prev + jump) % items.length);
        //     if (activeIdx + 1 >= items.length) {
        //         setActiveIdx(0);
        //       }
        // }
        const newActiveIdx = (activeIdx + 1) % items.length;
        if (newActiveIdx === 0) {
            const newItems = [];
            for (let i = 0; i < items.length; i++) {
                newItems.push(items[i]);
            }
            setItems(newItems);
        }
        setActiveIdx(newActiveIdx);
    };

    const handleDotClick = (idx) => {
        if (idx < activeIdx) prevClick(activeIdx - idx);
        if (idx > activeIdx) nextClick(idx - activeIdx);
    };

    useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);



    // const bind = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    //     if (down && distance > 100) {
    //         cancel();
    //         setActiveIdx((prev) => (prev + (xDir > 0 ? -1 : 1) + items.length) % items.length);
    //     }
    // });
    const bind = useGesture({
        onDrag: ({ direction: [xDir], distance }) => {
            if (distance > 10) {
                if (xDir > 0) {
                    prevClick();
                } else {
                    nextClick();
                }
            }
        },
    });

    if (items.length === 0) return <div>Loading...</div>;


    const CarouselSlideItem = ({ pos, idx, activeIdx, items }) => {

        const navigate = useNavigate();
        const item = createItem(pos, idx, items);
        // const translateX = (idx - activeIdx) * slideWidth + 'rem';
        const translateX = (idx - activeIdx + items.length) % items.length * slideWidth + 'em';
        console.log("CarouselSlideItem", item?.player?.id);
        // const translateX = (idx - activeIdx + items.length) % items.length * (window.innerWidth <= 768 ? 25 : slideWidth) + 'rem';


        return (
            <>
                <li className="carousel__slide-item  hover:border-red-800 rounded-lg cursor-pointer relative"
                    // style={item.styles}
                    style={{ transform: `translateX(${translateX})` }}
                    onClick={() => navigate(`/listing`, { state: { selectedCategoryValue: item?.player?.id } }
                        // || setHovered(false)
                    )}
                >
                    <div className="carousel__slide-item-img-link text-center rounded-lg">
                        <img src={item.player.categoryImage} alt={item.player.categoryName} className='rounded-lg' />

                    </div>
                    <div className="carousel-slide-item__body  absolute w-full bottom-2"

                    >
                        <div className='text-sm text-white bg-black rounded-full px-3 text-center w-fit mx-auto '
                            style={{ backgroundColor: 'rgb(0 0 0 / 50%)' }}
                        >
                            {item.player.categoryName}</div>
                    </div>
                </li>
            </>
        );
    };

    const handleItemClick = (item) => {
        navigate(`/listing`, { state: { selectedCategoryValue: item?.id } });
    };

    const getSlideItems = () => {
        const slides = [];
        // const slideSize = window.innerWidth <= 768 ? 4 : 8;
        let slideSize;

        if (window.innerWidth >= 1536) { // 2xl
            slideSize = 8;
        } else if (window.innerWidth >= 1280) { // xl
            slideSize = 8;
        } else if (window.innerWidth >= 1024) { // lg
            slideSize = 8;
        } else if (window.innerWidth >= 768) { // md
            slideSize = 6;
        } else if (window.innerWidth >= 640) { // sm
            slideSize = 5;
        } else if (window.innerWidth >= 540) { // sm
            slideSize = 5;
        }
        else if (window.innerWidth >= 440) { // sm
            slideSize = 4;
        } else if (window.innerWidth >= 340) { // sm
            slideSize = 3;
        } else if (window.innerWidth >= 240) { // sm
            slideSize = 2;
        }

        else { // xs
            slideSize = 4;
        }

        for (let i = 0; i < items.length; i += slideSize) {
            slides.push(items.slice(i, i + slideSize));
        }

        return slides;
    };

    const duplicatedItems = [...items, ...items, ...items];

    const handleRequestChange = (i) => {
        const itemCount = items.length;
        if (i >= itemCount && i < 2 * itemCount) {
            setIndex(i);
        } else if (i >= 2 * itemCount) {
            setIndex(i - itemCount);
        } else {
            setIndex(i + itemCount);
        }
    };

    return (
        <>
            <section className="flex flex-col items-center pt-10 pb-16">
                <div className={` flex flex-col self-center px-5 w-full max-w-${width} max-md:max-w-full`}>
                    <div className='flex items-center'>
                        <div className="flex gap-5 justify-between w-full max-md:flex-wrap items-center max-md:max-w-full">

                            <HeadingTextImageWrapper
                                text="Categories"
                                className="w-auto text-left"
                                textClassName='pe-10 ps-5'
                                alignTextWrapperClassName="text-left"
                            />

                        </div>

                        {/* <div className='flex items-center gap-5'>
                            <button
                                className="focus:ring-0 focus:border-0 focus:outline-0 " onClick={() => prevClick()}
                            >
                                <i className='fa fa-chevron-circle-left text-3xl mybzb_text_clr'></i>
                            </button>
                            <button
                                className="focus:ring-0 focus:border-0 focus:outline-0 " onClick={() => nextClick()}
                            >
                                <i className='fa fa-chevron-circle-right text-3xl mybzb_text_clr'></i>
                            </button>
                        </div> */}
                    </div>
                    {/* <div

                        className="mt-12 max-md:mt-10 max-md:max-w-full flex items-center gap-5"
                    >


                        <div
                            className="carousel__wrap px-5"
                        >
                            <div

                                className="carousel__inner"
                            >

                                <div className="carousel__container">
                                    <ul
                                       // {...bind()}
                                        className="carousel__slide-list"
                                    >
                                        {items.map((pos, i) => (
                                            <CarouselSlideItem
                                                key={i}
                                                idx={i}
                                                pos={(i - activeIdx + items.length) % items.length}
                                                activeIdx={activeIdx}
                                                items={items}

                                            />
                                        ))}
                                    </ul>
                                </div>


                            </div>
                        </div>



                    </div> */}

                    {/* <Gallery
                        index={index}
                        onRequestChange={i => {
                            setIndex(i);
                        }}
                        
                    >
                        {items.map((item, i) => (
                            <GalleryImage
                                key={i}
                                // objectFit="contain"
                                src={item?.categoryImage}
                                alt={item?.categoryName}
                                onClick={() => handleItemClick(item)}
                            />
                        ))}

                    </Gallery> */}
                    <Gallery
                        index={index}
                        showIndicators={false}
                        onRequestChange={i => setIndex(i)}
                        className='mt-12'
                        style={{
                            //  width: '100%',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        thumbnails={false}
                    // thumbnailsClassName='custom-thumbnails'

                    >

                        {getSlideItems().map((slide, i) => (
                            <div key={i}
                                className={`mx-5 w-full flex-wrap justify-center items-center grid mobile-grid-classes 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-5 max-xs:grid-cols-5 flex flex-row gap-5 max-sm:gap-3`}
                            //className='flex justify-center gap-3 items-center border border-red-500 h-96'
                            // className='w-full flex-wrap  justify-center items-center grid  xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-7 sm:grid-cols-5  gap-5 flex flex-row'
                            // className='grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-4 max-xs:grid-cols-5 flex flex-row gap-5 '
                            >
                                {slide.map((item, j) => (
                                    <div key={j}
                                        className='relative flex flex-col w-full '
                                        // className='relative'
                                        // className='w-full text-center flex justify-center max-xs:flex-row relative'
                                        //  className='relative justify-center items-center grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-4 max-xs:grid-cols-5 flex max-xs:flex-row gap-5'
                                        // style={{ flex: '1 1 calc(25% - 1rem)', maxWidth: 'calc(25% - 1rem)', boxSizing: 'border-box' }}
                                         onClick={() => handleItemClick(item)}
                                        >
                                        <GalleryImage
                                            src={item?.categoryImage}
                                            alt={item?.categoryName}
                                            className='rounded-lg max-sm:max-h-20 max-sm:w-20 max-sm:mx-auto'

                                        //    style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                                        />
                                        {/* <div className='tooltip-mobile'>
                                            <span className='tooltip-text'>{item?.categoryName}</span>
                                        </div> */}
                                        {window.innerWidth < 768 ? (
                                        <div className='w-full text-center  bottom-2 px-3 '>
                                            <div className="text-sm max-sm:text-[12px] text-center mt-2 px-3 text-black max-xs:px-0 leading-none"
                                              
                                            >
                                                {item?.categoryName}
                                            </div>
                                        </div>
                                        ):(
                                        <div className='w-full text-center absolute bottom-2 px-3 '>
                                            <div className="text-sm max-sm:text-[8px] text-center mt-2  bg-black rounded-full px-3 text-white max-xs:px-0"
                                                style={{ backgroundColor: 'rgb(0 0 0 / 50%)' }}
                                            >
                                                {item?.categoryName}
                                            </div>
                                        </div>
                                        )
                                    }

                                    </div>
                                ))}
                            </div>
                        ))}
                    </Gallery>

                </div>
            </section>

        </>
    );
};

export default CategoriesSlider

