import React, { useEffect, useState } from 'react'
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderModalBox from './OrderModalBox';



const MyOrderTabContent = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [ previewData,setPreviewData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    orderList();
  }, [])


  const orderList = async () => {

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);


      const result = await fetchWithFormDataToken(`api/v1/place_order_list`, formdata, abortController, null);
      if (result?.status === true) {
        // toast.success(result?.message, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });

        setData(result?.data?.order_data)

      }
      else {
        // toast.error(result?.message, {
        //   position: 'top-center',
        //   autoClose: 2000,
        // });
      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const orderDetailList = async (id) => {

    console.log("orderDetailList",id);

    setIsModalOpen(true)

    const tokenValue = localStorage?.getItem('login api response token');

    const user_id = localStorage?.getItem('user_data');
    const userId = JSON.parse(user_id);

    const storedToken = JSON?.parse(tokenValue);
    if (!storedToken) {
      navigate('/register');
      // return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("user_id", userId?.user_id);
      formdata.append("order_id", id);


       const result = await fetchWithFormDataToken(`api/v1/place_order_details`, formdata, abortController, null);
       if (result?.status === true) {
        setPreviewData()
       }
       else {
       }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  console.log("order list data", data);


  const SearchBar = () => {
    return (
      <div className="flex gap-3 self-stretch px-3 py-2 my-auto text-sm leading-5 bg-white rounded-lg text-stone-400 border">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bff447ded87998e133b50ce4e6126d8a54624089bc99dd88f323b0225c8a4ac3?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Search icon" className="shrink-0 self-start w-5 aspect-square" />
        <div className="flex-1">Search for anything</div>
      </div>
    );
  }


  const RenderContent = (item) => {
    console.log("RenderContent", item);
    if (!item?.item) return null; // return null if item is null or undefined

    let statusText = '';
    let statusColor = '';

    switch (item?.item?.order_status) {
      case '1':
        statusText = 'Pending';
        statusColor = 'text-orange-500';
        break;
      case '2':
        statusText = 'Order Confirm';
        statusColor = 'text-green-500';
        break;
      case '3':
        if (item?.item?.mode_of_payment === '0') {
          statusText = 'Ready For Pickup';
          statusColor = 'text-blue-600';
        } else {
          statusText = 'Ready For Shipping';
          statusColor = 'text-green-700';
        }
        break;
      case '4':
        if (item?.item?.mode_of_payment === '0') {
          statusText = 'Picked Up';
          statusColor = 'text-green-700';
        } else {
          statusText = 'Delivered';
          statusColor = 'text-green-500';
        }
        break;
      case '5':
        statusText = 'Completed';
        statusColor = 'text-green-500';
        break;
      case '6':
        statusText = 'Cancelled';
        statusColor = 'text-red-500';
        break;
      case '7':
        statusText = 'Return';
        statusColor = 'text-orange-500';
        break;
      default:
        return null;
    }

    return (
      <span className={`text-sm ${statusColor}`}>
        <i className="fa fa-circle text-xs me-2" />
        {statusText}
      </span>
    );
  };

  const previewOrder = () => {
    return (
      <>
        {data.length > 0 ? (
          data.map((item, index) => (
            <section
              className=" flex flex-col grow justify-center rounded-xl max-md:mt-10 max-md:max-w-full border p-5"
              key={index}
            >
              <div className='flex justify-between gap-5' >
                <div className='flex gap-5'>
                  <ImgComp
                    className={`w-32 h-28 border rounded-xl`}
                    src={item?.item_image ? item?.item_image : Images.logo}
                  />
                  <div className='flex justify-center gap-1 items-start flex-col'>
                    <div className='mybzb_text_clr font-bold text-sm'>
                      Order ID:- {item?.display_order_id}
                    </div>
                    <div
                      className='text-2xl font-medium'
                    >
                      {item?.product_name}
                    </div>
                    {/* <div className=' mt-2 flex justify-between gap-0'>
                    <div className='flex gap-5 flex-wrap w-full'>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Brand :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Category :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Sku :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Weight :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Size :</div>
                        <div className=' py-1 px-2 font-bold mybzb_bg_pinkclr mybzb_text_clr text-xs rounded-xl'>16.6mm</div>
                      </div>
                    </div>

                    <div className='text-sm text-stone-400 w-full gap-1 flex justify-end'>
                      <div>Availability :</div>
                      <div className='text-green-400 font-bold'>In Stock</div>
                    </div>


                  </div> */}
                    <div className='flex justify-between items-center'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-xl mybzb_text_clr font-bold'>
                          ₹ {Number(item?.order_amount).toLocaleString('en-IN')}
                        </div>
                        {/* <div className='text-lg text-stone-300 line-through'>
                        ₹ 20,000
                      </div>
                      <div className='text-sm text-green-400 px-2 py-1 rounded-xl bg-green-100'>
                        5% off
                      </div> */}
                      </div>

                    </div>
                  </div>

                </div>
                <div className='flex flex-col justify-between items-end '>
                  <div className='text-sm text-neutral-500 text-end'>
                    {/* <i className="fa fa-heart text-red-800 text-xl"></i> */}
                    {item?.order_date}
                  </div>
                  <div className='text-end w-full rounded-md'>
                    {/* {renderContent(item)} */}
                    <RenderContent item={item} />
                  </div>
                </div>
              </div>

            </section>
          ))

        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      <section className="flex flex-col grow justify-center rounded-xl  max-md:max-w-full">
        <h1 className="text-3xl font-bold text-left text-zinc-800 max-md:max-w-full">
          My Orders
        </h1>
      </section>
      <section className='my-5 flex justify-between items-center max-md:flex-col max-md:items-end gap-5'>
        <div className='w-96 max-sm:w-full'>
          <SearchBar />
        </div>
        <div className='flex gap-5 items-center  max-md:gap-2 max-sm:mt-3'>
          <div className='md:text-sm'>Sort by:</div>
          <div className='border text-sm text-stone-400  px-3 py-2 rounded-xl'>
            Most Popular
            <i className='fa fa-angle-down ms-3'></i>
          </div>
        </div>
      </section>
      {data.length > 0 ? (
        data.map((item, index) => (
          <section
            className=" cursor-pointer flex flex-col grow justify-center rounded-xl max-md:mt-10 max-md:max-w-full border p-5"
            key={index}
          >
            <div className='flex justify-between gap-5 '  onClick={() => orderDetailList(item?.id)} >
              <div className='flex gap-5 max-sm:gap-0'>
                <ImgComp
                  className={`w-32 h-28 border rounded-xl`}
                  src={item?.item_image ? item?.item_image : Images.logo}
                />
                <div className='flex justify-center gap-1 items-start flex-col'>
                  <div className='mybzb_text_clr font-bold text-sm'>
                    Order ID:- {item?.display_order_id}
                  </div>
                  <div
                    className='text-2xl font-medium'
                  >
                    {item?.product_name}
                  </div>
                  {/* <div className=' mt-2 flex justify-between gap-0'>
                    <div className='flex gap-5 flex-wrap w-full'>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Brand :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Category :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Sku :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Weight :</div>
                        <div className='text-black font-bold'>MyBzb</div>
                      </div>
                      <div className='text-sm text-stone-400 flex gap-1'>
                        <div>Size :</div>
                        <div className=' py-1 px-2 font-bold mybzb_bg_pinkclr mybzb_text_clr text-xs rounded-xl'>16.6mm</div>
                      </div>
                    </div>

                    <div className='text-sm text-stone-400 w-full gap-1 flex justify-end'>
                      <div>Availability :</div>
                      <div className='text-green-400 font-bold'>In Stock</div>
                    </div>


                  </div> */}
                  <div className='flex justify-between items-center'>
                    <div className='flex gap-5 items-center'>
                      <div className='text-xl mybzb_text_clr font-bold'>
                        ₹ {Number(item?.order_amount).toLocaleString('en-IN')}
                      </div>
                      {/* <div className='text-lg text-stone-300 line-through'>
                        ₹ 20,000
                      </div>
                      <div className='text-sm text-green-400 px-2 py-1 rounded-xl bg-green-100'>
                        5% off
                      </div> */}
                    </div>

                  </div>
                </div>

              </div>
              <div className='flex flex-col justify-between items-end '>
                <div className='text-sm text-neutral-500 text-end'>
                  {/* <i className="fa fa-heart text-red-800 text-xl"></i> */}
                  {item?.order_date}
                </div>
                <div className='text-end w-full rounded-md'>
                  {/* {renderContent(item)} */}
                  <RenderContent item={item} />
                </div>
              </div>
            </div>

          </section>
        ))

      ) : (
        <></>
      )}

      {isModalOpen && (
        <OrderModalBox
          title="Add Review Rating"
          content={previewOrder}
          onClose={() => setIsModalOpen(false)}
          modalWidth={`max-w-3xl`}
        />
      )}

      <ToastContainer />
    </>
  )
}

export default MyOrderTabContent